import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MastersService } from '../../../../../src/app/core/services';
import { IAppState, IRestOptions } from '../../interfaces';
import { ReviewsService } from '../../services/rest/reviews/reviews.service';
import { FormEffects, PagesEffects } from '../../store';

export const addReviewFormName = 'addReviewFormName';
export const loadReviews = 'loadReviews';
export const loadReviewsPush = 'loadReviewsPush';

@Injectable()
export class ReviewsEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly router: Router,
		private readonly store: Store<IAppState>,
		private readonly formEffects: FormEffects,
		private readonly mastersService: MastersService,
		private readonly pagesEffects: PagesEffects,
		private readonly reviewsService: ReviewsService,
	) {}

	public readonly $addReview = this.formEffects.getSendFormEffect(addReviewFormName, formState => {
		return this.reviewsService.addReview(formState.formData);
	});

	public readonly loadReviews$ = this.pagesEffects.getLoadPageEffect(loadReviews, ({ reqData }) => {
		return this.reviewsService.loadReviews(reqData);
	});

	public readonly loadReviewsPush$ = this.pagesEffects.getLoadPagePushEffect(loadReviewsPush, ({ page, reqData }: IRestOptions) => {
		return this.reviewsService.loadReviews({ page, ...reqData });
	});
}
