export interface AddressesModel {
    id: number;
    street: string;
    city: string;
    country: string;
    lat: number;
    lng: number;
    postalCode: string;
    userId: number;
}

export interface IAddresses {
    city: string;
    country: string;
    street: string;
    lat: number;
    lng: number;
    latlng: string;
    postal_code: string;
}
