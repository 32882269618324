import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UrlMasks } from '../../../enums';
import { ICollectionResponse, IMessage } from '../../../interfaces';

@Injectable({
	providedIn: 'root',
})
export class MessagesService {
	constructor(private readonly http: HttpClient) {}

	public getAll(params: { receiver: number; page: number }): Observable<ICollectionResponse<IMessage[]>> {
		const principal: { id: string } = JSON.parse(localStorage.getItem('principal'));

		return this.http.get<ICollectionResponse<IMessage[]>>(`messages?receiver=${params.receiver}&sender=${principal.id}`, { params });
	}

	public getAllUnread(): Observable<ICollectionResponse<IMessage[]>> {
		const principal: { id: string } = JSON.parse(localStorage.getItem('principal'));

		if (!principal?.id) {
			return of();
		}

		return this.http.get<ICollectionResponse<IMessage[]>>(`messages-unread?receiver=${principal.id}`);
	}

	public getAllDirects(): Observable<any> {
		return this.http.get(`messages/${UrlMasks.UserId}`);
	}

	public create(sendData: { receiver: number; message: string }): Observable<any> {
		const principal = JSON.parse(localStorage.getItem('principal'));

		const data = {
			...sendData,
			sender: principal.id,
		};

		return this.http.post(`messages`, data);
	}

	public sendMessage(data: {
		receiver: number;
		message: string;
		files: File | File[];
		is_read: boolean;
		send_sms?: boolean;
		phone?: string;
		chat_id?: string;
		is_group?: boolean;
		reply_to?: number;
	}): Observable<any> {
		const formData = new FormData();
		const principal = JSON.parse(localStorage.getItem('principal'));

		formData.append('receiver', +data?.receiver ? data.receiver.toString() : null);
		formData.append('sender', principal?.id);
		formData.append('message', data?.message ? data.message : null);
		formData.append('send_sms', String(data?.send_sms ? data.send_sms : false));
		formData.append('is_read', String(data?.is_read ? data.is_read : false));
		formData.append('phone', String(data?.phone ? data.phone : null));
		formData.append('chat_id', String(data?.chat_id ? data.chat_id : null));
		formData.append('is_group', String(data?.is_group ? data.is_group : null));
		formData.append('reply_to', String(data?.reply_to ? data.reply_to : null));

		// Если files - это массив файлов, добавляем их по отдельности
		if (data?.files) {
			if (Array.isArray(data.files)) {
				data.files.forEach(file => {
					formData.append('images', file, file.name);
				});
			} else {
				// Если это один файл
				formData.append('images', data.files, data.files.name);
			}
		}

		return this.http.post('/messages/with-files', formData);
	}

	public deleteItem(itemId: number): Observable<number> {
		return this.http.delete<number>(`messages/${itemId}`);
	}

	public deleteAllMessages(user_id: number): Observable<number> {
		return this.http.delete<number>(`messages/all/${user_id}`);
	}
}
