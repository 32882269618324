<ion-modal [breakpoints]="[0, 1]" [initialBreakpoint]="1" [canDismiss]="true" mode="ios" [isOpen]="isOpen" (willDismiss)="onDismiss()">
	<ng-template>
		<hb-header>
			<ion-toolbar>
				<ion-title>{{ 'SELECT_MANAGERS' | translate }}</ion-title>
				<ion-buttons slot="end">
					<ion-button (click)="confirm()">
						{{ 'SAVE' | translate }}
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
			<ion-searchbar
				[placeholder]="'SEARCH' | translate"
				class="ion-margin-top"
				mode="ios"
				[debounce]="1000"
				(ionInput)="handleInput($event)"
			></ion-searchbar>
		</hb-header>
		<ion-content>
			<ion-list [inset]="true">
				<ion-list-header>
					{{ 'SELECTED_MANAGERS' | translate }}
				</ion-list-header>
				@if (checkedUsersArray.length) {
					@for (result of checkedUsersArray; track result) {
						<ion-item>
							<ion-checkbox [checked]="true" (ionChange)="onChange(result)" justify="space-between">
								<div class="flex ion-align-items-center">
									<hb-avatar
										class="ion-margin-end"
										[data]="{ user_name: result.user_name, img: result.img, id: result.id }"
										size="small"
									></hb-avatar>
									<ion-label>{{ result.user_name ? result.user_name : (result | fullName) }}</ion-label>
								</div>
							</ion-checkbox>
						</ion-item>
					}
				} @else {
					<div class="flex h100 ion-justify-content-center ion-padding">
						{{ 'NO_MANAGERS' | translate }}
					</div>
				}
			</ion-list>

			@if (results.length) {
				<ion-list [inset]="true">
					<ion-list-header>
						{{ 'GENERAL_SEARCH_LIST' | translate }}
					</ion-list-header>
					@for (result of results; track result) {
						<ion-item>
							<ion-checkbox (ionChange)="onChange(result)" justify="space-between">
								<div class="flex ion-align-items-center">
									<hb-avatar
										class="ion-margin-end"
										[data]="{ user_name: result.user_name, img: result.img, id: result.id }"
										size="small"
									></hb-avatar>
									<ion-label>{{ result.user_name ? result.user_name : (result | fullName) }}</ion-label>
								</div>
							</ion-checkbox>
						</ion-item>
					}
				</ion-list>
			}
		</ion-content>
	</ng-template>
</ion-modal>
