import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'localizedDate',
	pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
	constructor(private readonly translateService: TranslateService) {}

	public transform(value: any, format: string = ''): any {
		const locale = this.translateService.currentLang;

		// Выбор формата в зависимости от языка
		const localizedFormat = format || (locale === 'ru' ? 'dd.MM.yyyy HH:mm' : 'MM/dd/yyyy HH:mm');

		const datePipe = new DatePipe(locale);
		return datePipe.transform(value, localizedFormat);
	}
}
