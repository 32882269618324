import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SeeTranslateComponent } from '../see-translate/see-translate.component';

@Component({
	selector: 'hb-show-more',
	templateUrl: './show-more.component.html',
	styleUrls: ['./show-more.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, TranslateModule, SeeTranslateComponent],
})
export class ShowMoreComponent implements OnInit {
	@Input() public text: string;
	@Input() public maxLength: number = 100;
	public isExpanded: boolean = false;
	public truncatedText: string = '';

	public ngOnInit(): void {
		if (this.text) {
			this.truncatedText = this.text.substring(0, this.maxLength);
		}
	}

	protected toggleText(): void {
		this.isExpanded = !this.isExpanded;
	}
}
