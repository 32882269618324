import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { isEmptyData } from '@solar/core/src/services/utils';
import { UserRes } from '@solar/core/src/store';
import { ServerTranslatePipe } from '@solar/shared';

@Component({
	selector: 'hb-user-services',
	templateUrl: './user-services.component.html',
	styleUrls: ['./user-services.component.scss'],
	imports: [IonicModule, TitleCasePipe, ServerTranslatePipe, NgClass],
})
export class UserServicesComponent {
	@Input() public user: UserRes;
	@Input() public color: 'light' | 'medium' | 'dark' = 'medium';
	@Input() public ngClass: string;

	protected readonly isEmptyData = isEmptyData;
}
