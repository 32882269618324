import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@solar/core/src';
import { selectPageByName } from '@solar/core/src/store';
import { getUser } from '@solar/core/src/store/user/user-effects';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { filter, map, Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[showOnlyForUser]',
})
export class ShowOnlyForUserDirective implements OnInit, OnDestroy {
	@Input()
	public showOnlyForUser?: number[]; // Массив пользователей

	private readonly onDestroy = new Subject<void>();

	constructor(
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainer: ViewContainerRef,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly store: Store<IAppState>,
	) {}

	public ngOnInit(): void {
		this.checkUser();
	}

	public checkUser(): void {
		this.store
			.select(selectPageByName(getUser))
			.pipe(
				filter(r => !!r?.data), // Строгая проверка на наличие данных
				map(res => res.data), // Извлекаем data, чтобы избежать повторных обращений
				takeUntil(this.onDestroy),
			)
			.subscribe(user => {
				this.viewContainer.clear(); // Очищаем контейнер

				// Если id пользователя находится в массиве или это админ — показываем
				if (this.showOnlyForUser?.includes(user.id) || user.role === UserRolesEnum.ADMIN) {
					this.viewContainer.createEmbeddedView(this.templateRef);
					this.changeDetector.detectChanges(); // Обновляем состояние компонента
				}
			});
	}

	public ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}
}
