import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectForms } from '@solar/core/src/store/forms/selectors';
import { IAppState, IFormState, IPayloadFormState } from '@solar/interfaces';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddForm, ResetForm, ResetFormError, SendForm, SetFormData } from '../../../core/src/store/forms';

@Directive({
	selector: '[formConnect]',
})
export class FormConnectDirective<T = any> implements OnInit, OnDestroy {
	@Input('formConnect')
	public formName?: string;

	@Output()
	public onSuccessForm: EventEmitter<any> = new EventEmitter<any>();

	@Output()
	public isPending: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input()
	public resetForm: boolean = true;

	private readonly subscription: Subscription = new Subscription();

	public static markAsTouchedAllControls(form: FormGroup): void {
		form.markAsTouched();
		form.updateValueAndValidity();

		if (form.controls) {
			Object.values(form.controls).forEach((control: FormGroup) => {
				control.markAsTouched();
				control.updateValueAndValidity();

				const controls = control.controls;

				if (controls) {
					Object.values(controls).forEach(FormConnectDirective.markAsTouchedAllControls);
				}
			});
		}
	}

	constructor(
		private readonly formGroupDirective: FormGroupDirective,
		private readonly store: Store<IAppState>,
	) {}

	public ngOnInit(): void {
		this.store.dispatch(
			AddForm({
				formName: this.formName,
				formData: this.formGroupDirective?.form?.value as T,
			}),
		);

		this.setFormControlsVal();

		this.listenSubmit();

		this.listenValueChanges();

		this.listenForm();
	}

	public setFormControlsVal(): void {
		this.store
			.select<IPayloadFormState>(selectForms)
			.pipe(take(1))
			.subscribe(res => {
				const form = res[this.formName]?.formState as IFormState<T>;

				if (form?.formData) {
					this.formGroupDirective.form.patchValue(form.formData);
				}
			});
	}

	public listenForm(): void {
		this.subscription.add(
			this.store.select<IPayloadFormState>(selectForms).subscribe(res => {
				const form = res[this.formName]?.formState as IFormState<T>;

				this.isPending.emit(form?.isPending);

				this.checkErrors(form);

				this.emitSuccessEvent(form);
			}),
		);
	}

	public emitSuccessEvent(form: IFormState<T>): void {
		if (form && form.isSuccess) {
			this.onSuccessForm.emit(form.responseData || form.formData);

			if (this.resetForm) {
				this.store.dispatch(ResetForm({ formName: this.formName }));
			}
		}
	}

	public checkErrors(form: IFormState): void {
		if (form && form.error && form.error.fieldErrors) {
			const fields = form.error.fieldErrors;

			fields.forEach(it => {
				const control = this.formGroupDirective.form.controls[it.name];

				if (control) {
					control.setErrors({ server: it.messages });
					this.store.dispatch(ResetFormError({ formName: this.formName }));
				}
			});
		}
	}

	public listenSubmit(): void {
		this.subscription.add(
			this.formGroupDirective.ngSubmit.subscribe((event: Event) => {
				event.preventDefault();

				const form = this.formGroupDirective.form;
				this.touchControls(form);

				if (form.valid) {
					const formValues = form.getRawValue() as T;

					this.store.dispatch(SendForm({ formName: this.formName, formState: { formData: formValues } }));
				}
			}),
		);
	}

	public listenValueChanges(): void {
		this.subscription.add(
			this.formGroupDirective.form.valueChanges.subscribe((formData: T) => {
				this.store.dispatch(SetFormData({ formName: this.formName, formData }));
			}),
		);
	}

	public touchControls(form: FormGroup): void {
		FormConnectDirective.markAsTouchedAllControls(form);
	}

	public ngOnDestroy(): void {
		if (this.resetForm) {
			this.store.dispatch(ResetForm({ formName: this.formName }));
		}

		this.subscription.unsubscribe();
	}
}
