<ion-grid class="ion-no-padding">
	<ion-row>
		@for (it of hours; track i; let i = $index) {
			@if (i < maxLength) {
				<ion-col tappable [size]="it.colSize" (click)="!isDisabled && !it.isDisabled && onSelectTime(it.label)">
					<div
						class="hours-box relative"
						[ngClass]="{ 'color-pink': !isDisabled && selectedTime === it.label, disabled: isDisabled || it.isDisabled }"
					>
						{{ it.label }}
						@if (selectedTime === it.label) {
							<ion-icon name="checkmark-outline" class="font-size-14 selected-icon"></ion-icon>
						}
					</div>
				</ion-col>
			}
		}
	</ion-row>
</ion-grid>

@if (infoBox) {
	<div class="flex m-t-4">
		<div class="ion-margin-end flex ion-justify-content-center ion-align-items-center">
			<i class="dot"></i>
			&nbsp;&nbsp;{{ 'AVAILABLE' | translate }}
		</div>

		<div class="ion-margin-end flex ion-justify-content-center ion-align-items-center">
			<i class="dot medium"></i>
			&nbsp;&nbsp;{{ 'BOOKED' | translate }}
		</div>
	</div>
}
