export const getStartAndEndOfMonth = (year, month) => {
    const endDate = new Date(year, month, 0);
    const start_of_month = `${year}-${month}-01`;
    const end_of_month = `${year}-${month}-${endDate.getDate()}`;
    return { start_of_month, end_of_month };
};

export const getCurrentDateYearAndMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    return { year, month };
};
