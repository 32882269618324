import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { IChat } from '@solar/core/src/interfaces';
import { AlertService } from '@solar/core/src/services/alert/alert.service';
import { ChatsService } from './../../../../../../core/src/services/rest/chats/chats.service';

@Component({
	selector: 'hb-chat-actions',
	templateUrl: './chat-actions.component.html',
	styleUrls: ['./chat-actions.component.scss'],
	imports: [IonicModule, TranslateModule, RouterModule],
})
export class ChatActionsComponent {
	@Input() public chat: IChat;
	protected JSON: JSON = JSON;

	constructor(
		private readonly chatsService: ChatsService,
		private readonly authService: AuthService,
		private readonly router: Router,
		private readonly alertService: AlertService,
		private readonly translate: TranslateService,
	) {}

	public async onLeave(): Promise<void> {
		await this.alertService.presentAlert({
			header: this.translate.instant(this.chat?.is_group ? 'SURE_TO_LEAVE_GROUP' : 'SURE_TO_DELETE_CHAT'),
			mode: 'ios',
			buttons: [
				{
					text: this.translate.instant('NO'),
					role: 'cancel',
					handler: () => {},
				},
				{
					text: this.translate.instant('YES'),
					role: 'confirm',
					handler: async () => {
						await this.chatsService.leaveChat({
							chat_id: this.chat.chat_id,
							user_id: this.authService?.principal?.id,
						});

						await this.router.navigate(['/direct-messages']);
					},
				},
			],
		});
	}
}
