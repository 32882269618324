import { Component, input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { LocalizedDatePipe } from '@solar/shared';

@Component({
	selector: 'hb-edited-date',
	templateUrl: './edited-date.component.html',
	styleUrls: ['./edited-date.component.scss'],
	imports: [IonicModule, LocalizedDatePipe, TranslateModule],
})
export class EditedDateComponent {
	public order = input.required<IOrders>();
}
