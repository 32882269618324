export const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month - 1, 1);
    const days = [];

    while (date.getMonth() === month - 1) {
        days.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }

    return days.join(',');
};
