export interface IFormState {
	error?: any;
	responseData?: any;
	formData?: any;

	isPending?: boolean;
	isSuccess?: boolean;
	hasResponse?: boolean;
	isError?: boolean;
}

export interface IFormStateGeneric<T> {
	error?: any;
	responseData?: any;
	formData?: T;

	isPending?: boolean;
	isSuccess?: boolean;
	hasResponse?: boolean;
	isError?: boolean;
}

export const initFormState: IFormState = {
	error: null,
	formData: null,
	responseData: null,

	isPending: false,
	hasResponse: false,
	isSuccess: false,
	isError: false,
};

export interface IPayloadFormState {
	formName: string;
	formState: IFormState;
}

export const IFormInitialData = {
	formName: null,
	formState: null,
};

// FORMS END

export interface ILoadDataState<D> {
	isPending?: boolean;
	hasLoaded?: boolean;
	isEmpty?: boolean;
	data: D;
	error?: any;
}

export const InitialData = {
	isPending: false,
	isEmpty: false,
	hasLoaded: false,
	data: null,
	error: null,
};

export const initialDataFcm = { token: null };

export interface IFcmState {
	token: string;
}

export interface ICoreState {
	forms: any;
	pages: any;
}
