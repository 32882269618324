import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PaymentsService {
	constructor(private readonly http: HttpClient) {}

	public getPaymentsList(body: { customerId: number }): Observable<any> {
		return this.http.get<any>(`/payment-methods/${body.customerId}`);
	}

	public payoutsToDestination(body: { amount: number; currency: string; destination: string }): Observable<any> {
		return this.http.post<any>(`/payouts`, body);
	}
}
