import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const HTTP_STORAGE_TOKEN_KEY = new InjectionToken<string>('HTTP_STORAGE_TOKEN_KEY');

export class StorageTokenInterceptor implements HttpInterceptor {
	constructor(@Inject(HTTP_STORAGE_TOKEN_KEY) private readonly tokenKey: string) {}

	private get token(): string | null {
		return localStorage.getItem(this.tokenKey);
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this.token}`,
				},
			});
		}

		return next.handle(request);
	}
}
