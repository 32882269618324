import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseAuthService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class FirebaseAuthGuard {
    private _state: RouterStateSnapshot;
    private _next: ActivatedRouteSnapshot;
    constructor(public authService: FirebaseAuthService, public router: Router) {}
    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
        this._next = next;
        this._state = state;
        if (this.authService.isLoggedIn !== true) {
            void this.router.navigate(['login']);
        }
        return true;
    }
}
