import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { ICoreState } from './core-store.interface';
import { FormsReducers } from './forms';
import { PagesReducers } from './pages';

export const reducers: ActionReducerMap<ICoreState> = {
	forms: FormsReducers,
	pages: PagesReducers,
};

export const metaReducers: MetaReducer<ICoreState>[] = [];
