<ion-modal [initialBreakpoint]="1" [breakpoints]="[0, 1]" mode="ios" class="auto-height" [isOpen]="isOpen">
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button (click)="cancel()">{{ 'CLOSE' | translate }}</ion-button>
				</ion-buttons>
				<ion-buttons slot="end">
					@if ((formIsPending$ | async) === false) {
						<ion-button (click)="confirm()" [strong]="true">{{ 'SEND' | translate }}</ion-button>
					} @else {
						<ion-spinner name="dots" class="m-r-10" color="primary"></ion-spinner>
					}
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<div class="inner-content">
			<div class="image-list">
				@for (image of imagePreviews; track $index) {
					<div class="image-item">
						<img [src]="image.webPath" alt="Preview" class="preview-image" />
						<button mode="ios" (click)="removePreview($index)" class="remove-button font-size-16">
							<ion-icon name="close-circle-outline"></ion-icon>
						</button>
					</div>
				}
			</div>
		</div>

		<ion-footer>
			<ion-toolbar class="ion-no-padding">
				<ion-textarea class="m-16" [(ngModel)]="message" color="light" mode="ios" [placeholder]="'ENTER_TEXT' | translate"></ion-textarea>
			</ion-toolbar>
		</ion-footer>
	</ng-template>
</ion-modal>
