import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICollectionResponse } from '@solar/core/src';
import { lastValueFrom, Observable } from 'rxjs';

const pathName = '/main-sliders';

export interface IMainSlider {
	id: number;
	img: { url: string };
	is_active: boolean;
	link: string;
}

@Injectable({
	providedIn: 'root',
})
export class MainSlidersService {
	constructor(private readonly http: HttpClient) {}

	public loadAll(): Observable<ICollectionResponse<IMainSlider>> {
		return this.http.get<ICollectionResponse<IMainSlider>>(pathName);
	}
	public async getAll(): Promise<ICollectionResponse<IMainSlider>> {
		return await lastValueFrom(this.http.get<ICollectionResponse<IMainSlider>>(pathName));
	}

	public add(body: IMainSlider): Observable<[]> {
		return this.http.post<[]>(pathName, this.formData(body));
	}

	public edit(body: IMainSlider): Observable<[]> {
		return this.http.patch<[]>(pathName, this.formData(body));
	}

	public async delete(id: number): Promise<Object> {
		try {
			return await lastValueFrom(this.http.delete(`${pathName}/${id}`));
		} catch (e) {
			console.log(e);
		}
	}

	public async patch(body: { text: string; id: number }): Promise<Object> {
		try {
			return await lastValueFrom(this.http.patch(`${pathName}/${body.id}`, body));
		} catch (e) {
			console.log(e);
		}
	}

	// form data
	private formData(body): FormData {
		const formData = new FormData();
		formData.append('id', body.id.toString());
		if (!body.img?.url) formData.append('img', body.img);
		formData.append('is_active', body.is_active.toString());
		formData.append('link', body.link);

		return formData;
	}
}
