import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UserSearchStateService } from '@solar/core/src';
import { UserService } from '@solar/core/src/services/rest/user';
import { UserRes } from '@solar/core/src/store';
import { AvatarComponent, FullNamePipe, HeaderComponent } from '@solar/shared';
import { MastersService } from '../../../../../../src/app/core/services';

@Component({
	selector: 'hb-user-search',
	templateUrl: './user-search.component.html',
	styleUrls: ['./user-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, ReactiveFormsModule, TranslateModule, FormsModule, AvatarComponent, FullNamePipe, HeaderComponent],
})
export class UserSearchComponent {
	public results: UserRes[] = [];

	@Output() public selection = new EventEmitter<UserRes[]>();
	@Output() public dismiss = new EventEmitter();

	@Input() public isOpen: boolean;
	public users: number[];

	protected checkedUsers = new Map<number, UserRes>();

	constructor(
		private readonly userService: UserService,
		private readonly ch: ChangeDetectorRef,
		private readonly mastersService: MastersService,
		private readonly userSearchStateService: UserSearchStateService,
	) {
		effect(() => {
			this.users = this.userSearchStateService.items();

			if (this.users?.length) {
				void this.mastersService.getAllMasters({ ids: this.users.join(','), role: null }).then((res: any): void => {
					if (res?.data) {
						res.data.map(u => {
							this.onChange(u);
						});
					}
				});
			}
		});
	}

	public confirm(): void {
		this.onDismiss();
	}

	public handleInput(event): void {
		void this.userService.searchUsersAsync(event.target.value).then(res => {
			if (res) {
				this.results = res;
			} else {
				this.results = [];
			}
			this.ch.markForCheck();
		});
	}

	protected onChange(item: UserRes): void {
		if (this.checkedUsers.has(item.id)) {
			this.checkedUsers.delete(item.id);
		} else {
			this.checkedUsers.set(item.id, item);
		}

		this.ch.markForCheck();
	}

	protected get checkedUsersArray(): UserRes[] {
		return [...this.checkedUsers].map(([_, obj]) => obj);
	}

	public onDismiss(): void {
		if (this.isOpen) {
			this.isOpen = false;
			void this.dismiss.emit(this.checkedUsersArray);
			this.checkedUsers.clear();
			this.userSearchStateService.items.set([]);
			this.results = [];
		}
	}
}
