import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@solar/core/src';
import { selectPageByName } from '@solar/core/src/store';
import { getUser } from '@solar/core/src/store/user/user-effects';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { filter, Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[showOnlyFor]',
})
export class ShowOnlyForDirective implements OnInit, OnDestroy {
	@Input('showOnlyFor')
	public onlyFor?: UserRolesEnum[];

	private readonly onDestroy = new Subject<void>();
	private isViewCreated = false;

	constructor(
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainer: ViewContainerRef,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly store: Store<IAppState>,
	) {}

	public ngOnInit(): void {
		this.store
			.select(selectPageByName(getUser))
			.pipe(
				filter(r => !!r?.data),
				takeUntil(this.onDestroy),
			)
			.subscribe(res => {
				const shouldShowView = res?.data?.role && this.onlyFor?.includes(res?.data?.role);

				if (shouldShowView && !this.isViewCreated) {
					// Создаем представление, если оно ещё не создано
					this.viewContainer.createEmbeddedView(this.templateRef);
					this.isViewCreated = true;
				} else if (!shouldShowView && this.isViewCreated) {
					// Удаляем представление, если оно не должно отображаться
					this.viewContainer.clear();
					this.isViewCreated = false;
				}

				// Отмечаем изменение, если необходимо
				this.changeDetector.markForCheck();
			});
	}

	public ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}
}
