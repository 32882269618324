export interface AddressesRes {
    id: number;
    user_id: number;
    street: string;
    city: string;
    country: string;
    zip_code: string;
    lat: string;
    lng: string;
}
