import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { IWorkingHours } from './working-hours.dto';

@Injectable({
	providedIn: 'root',
})
export class WorkingHoursService {
	constructor(private readonly http: HttpClient) {}

	public getWorkingHours(body: IWorkingHours): Observable<any> {
		return this.http.get<any>(`/working-hours/${body.master_id}`);
	}

	public setWorkingHours(body: IWorkingHours): Observable<[]> {
		return this.http.post<[]>(`/working-hours`, body);
	}

	public patchWorkingHours(body: IWorkingHours): Observable<[]> {
		return this.http.patch<[]>(`/working-hours/${body.master_id}`, body);
	}

	public getWorkingHoursUsers(body: { users_id: number[] }): Observable<any> {
		return this.http.post(`/working-hours-users`, body);
	}

	public async getWorkingHoursUsersAsync(body: { users_id: number[] }): Promise<any> {
		try {
			return await lastValueFrom(this.http.post(`/working-hours-users`, body));
		} catch (e) {
			console.log(e);
		}
	}
}
