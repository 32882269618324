import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { ICollectionResponse } from '@solar/core/src';
import { lastValueFrom } from 'rxjs';
import { IMessage } from '../../../interfaces';

@Injectable({
	providedIn: 'root',
})
export class MessagesStateService {
	public messages: WritableSignal<ICollectionResponse<IMessage>> = signal({ data: [] });

	constructor(private readonly http: HttpClient) {}

	public async load(params: { chat_id: string; page: number }): Promise<void> {
		const data = await this.fetchData(params);

		this.messages.set(data);
	}

	public async loadPush(params: { chat_id: string; page: number }): Promise<void> {
		const data = await this.fetchData(params);

		this.messages.update(state => {
			return {
				...state,
				page: data.page,
				pages: data.pages,
				size: data.size,
				data: [...data.data, ...state?.data],
			};
		});
	}

	public addMessage(item: IMessage): void {
		if (item?.uuid && item?.id) {
			this.setItemByUuid(item);
			return;
		}

		if (!item.uuid) {
			return;
		}

		this.messages.update(state => ({
			...state,
			data: [...state.data, item],
		}));
	}

	public setItemByUuid(item: IMessage | null): void {
		if (!item || !item.uuid || !item.id) {
			return;
		}

		const isExists = this.messages().data.find(it => it?.id && it?.id === item.id);

		if (isExists) {
			this.messages.update(state => {
				const data = state.data.map(it => {
					if (it?.id === item.id) {
						return item;
					}

					return it;
				});

				return {
					...state,
					data,
				};
			});
			return;
		}

		const isMockInMessages = this.messages().data.find(it => it?.uuid === item.uuid && !it.id);

		if (isMockInMessages) {
			try {
				this.messages.update(state => {
					const data = state.data.map(it => {
						if (it?.uuid === item.uuid && !it.id) {
							return item;
						}

						return it;
					});

					return {
						...state,
						data,
					};
				});
			} catch (error) {
				console.error(error);
			}
		} else {
			this.setItemIfNotExists(item);
		}
	}

	public setItemIfNotExists(item: IMessage): void {
		const isExists = this.messages().data.find(it => +it.id === +item.id);

		if (!isExists) {
			this.messages.update(state => ({
				...state,
				data: [...state.data, item],
			}));
		}
	}

	public delete(id: number): void {
		this.messages.update(state => ({
			...state,
			data: state.data.filter(it => it.id !== id),
		}));
	}

	public getMessageById(id: number): IMessage | null {
		return this.messages().data.find(it => it.id === id) || null;
	}

	public clear(): void {
		this.messages.set({ data: [] });
	}

	public async fetchData(params: { chat_id: string; page: number }): Promise<ICollectionResponse<IMessage>> {
		return await lastValueFrom(this.http.get<ICollectionResponse<IMessage>>(`messages`, { params }));
	}
}
