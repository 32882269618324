import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'hb-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [IonicModule, TranslateModule],
})
export class HeaderComponent implements OnInit {
	@Input() public title?: string;
	@Input() public backUrl?: string;
	@Input() public backUrlText?: string = 'BACK';
	@Input() public toolbarClass?: string = 'sr-content-bg';

	@ViewChild('template', { static: true }) public template: TemplateRef<unknown>;

	constructor(public readonly viewContainerRef: ViewContainerRef) {}

	public ngOnInit(): void {
		this.viewContainerRef.createEmbeddedView(this.template);
	}
}
