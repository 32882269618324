import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TranslateRestService {
	constructor(private readonly http: HttpClient) {}

	public async load(body: { text: string; lang: string }): Promise<{ text: string }> {
		try {
			return await lastValueFrom(this.http.post<{ text: string }>('/translate', body));
		} catch (e) {
			console.log(e);
		}
	}
}
