export interface IMessage {
	reactions?: IReactions[];
	id: number;
	message: string;
	created_at: string;
	is_read: boolean;
	receiver: number;
	sender: number;
	user_info?: { id: number; img: string; first_name: string; last_name: string; user_name?: string };
	files: any;
	uuid?: string;
}

export interface IReactions {
	id?: number;
	count?: number;
	reaction_type?: string;
	users?: IReactionUser[];
}

export interface IReactionUser {
	user_id: number;
	user_img: string;
	user_name: string;
}
