import { Directive, HostListener } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Platform } from '@ionic/angular';

@Directive({
	selector: '[haptic]',
})
export class HapticDirective {
	constructor(private readonly platform: Platform) {}

	@HostListener('ionTabsWillChange', ['$event'])
	public async onClick(): Promise<void> {
		if (this.platform.is('ios') && this.platform.is('capacitor')) {
			await Haptics.impact({ style: ImpactStyle.Light });
		}
	}
}
