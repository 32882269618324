<ion-content>
	<ion-list mode="ios" [inset]="true" class="margin-no">
		<ion-item
			color="light"
			[button]="true"
			[detail]="false"
			[routerLink]="chat?.is_group ? '/direct-messages/info' : '/card/' + chat?.user_id + '/master'"
			[queryParams]="{ chat: JSON.stringify(chat) }"
		>
			<ion-icon name="information-circle-outline"></ion-icon>
			<ion-text class="m-l-10 font-size-14">{{ 'INFO' | translate }}</ion-text>
		</ion-item>

		<ion-item color="light" (click)="onLeave()" [button]="true" [detail]="false">
			@if (chat?.is_group) {
				<ion-icon name="exit-outline" color="danger"></ion-icon>
				<ion-text class="m-l-10 font-size-14" color="danger">
					{{ 'LEAVE_GROUP' | translate }}
				</ion-text>
			} @else {
				<ion-icon name="exit-outline" color="danger"></ion-icon>
				<ion-text class="m-l-10 font-size-14" color="danger">
					{{ 'DELETE_CHAT' | translate }}
				</ion-text>
			}
		</ion-item>
	</ion-list>
</ion-content>
