import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Haptics } from '@capacitor/haptics';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { normalizeTime } from '@solar/core/src/utils/date';

export interface IHours {
	id?: number;
	colSize: string;
	label: string;
	isDisabled: boolean;
	checked?: boolean;
	order?: IOrders;
}

@Component({
	selector: 'hb-hours-grid',
	templateUrl: './hours-grid.component.html',
	styleUrls: ['./hours-grid.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, TranslateModule, NgClass],
})
export class HoursGridComponent {
	@Input() public isDisabled: boolean = false;

	@Input() public infoBox: boolean = true;

	@Input() public hours: IHours[];

	@Input() public selectedTime: string;

	@Input() public maxLength: number = 20;

	@Output() public selected: EventEmitter<string> = new EventEmitter();

	public onSelectTime(time: string): void {
		this.selectedTime = time;
		this.selected.emit(normalizeTime(this.selectedTime));
		void Haptics.selectionChanged().then();
	}
}
