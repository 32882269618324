import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@solar/core/src';
import { selectPageByName } from '@solar/core/src/store';
import { getUser } from '@solar/core/src/store/user/user-effects';
import { filter, Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[showExceptMe]',
})
export class ShowExceptMeDirective implements OnInit, OnDestroy {
	@Input()
	public showExceptMe?: number;

	private readonly onDestroy = new Subject<void>();
	constructor(
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainer: ViewContainerRef,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly store: Store<IAppState>,
	) {}

	public ngOnInit(): void {
		this.store
			.select(selectPageByName(getUser))
			.pipe(
				filter(r => r?.data),
				takeUntil(this.onDestroy),
			)
			.subscribe(res => {
				this.viewContainer.clear();

				if (res?.data?.id !== this.showExceptMe) {
					this.viewContainer.createEmbeddedView(this.templateRef);
				} else {
					this.viewContainer.clear();
				}

				this.changeDetector.detectChanges();
			});
	}

	public ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}
}
