import { Injectable } from '@angular/core';
import { FormEffects } from '../../store';
import { PrintersService } from './../../services/rest/printers/printers.service';

export const addPrinterFormName = 'addPrinterFormName';
export const editPrinterFormName = 'editPrinterFormName';
export const loadPrinters = 'loadPrinters';

@Injectable()
export class PrintersEffects {
	constructor(
		private readonly formEffects: FormEffects,
		private readonly printersService: PrintersService,
	) {}

	public readonly $addPrinter = this.formEffects.getSendFormEffect(addPrinterFormName, formState => {
		return this.printersService.addPrinter(formState.formData);
	});

	public readonly $editPrinter = this.formEffects.getSendFormEffect(editPrinterFormName, formState => {
		return this.printersService.editPrinter(formState.formData);
	});
}
