<ion-header mode="ios" class="ion-no-border">
	<hb-header>
		<ion-searchbar [placeholder]="'SEARCH' | translate" mode="ios" class="ion-margin-top" (ionInput)="filter($event)"></ion-searchbar>
	</hb-header>
</ion-header>

<ion-content [scrollY]="false">
	<cdk-virtual-scroll-viewport itemSize="56" minBufferPx="900" maxBufferPx="1350">
		<ion-list>
			<ion-item *cdkVirtualFor="let item of filtered" tappable (click)="onDismiss(item)">
				<ion-avatar class="code-phone-img" slot="start">
					<img [src]="item.icon" alt="country" />
				</ion-avatar>

				<ion-label>{{ item.label }}</ion-label>
			</ion-item>
		</ion-list>
	</cdk-virtual-scroll-viewport>
</ion-content>
