import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService, IAppState, ToastService } from '@solar/core/src';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { addMessage, SendForm } from '@solar/core/src/store';
import { selectFormByName } from '@solar/core/src/store/forms/selectors';
import { HeaderComponent } from '../../../components/stateless/header/header.component';
import { ChatsService } from './../../../../../core/src/services/rest/chats/chats.service';

@Component({
	selector: 'hb-message-modal',
	templateUrl: './message-modal.component.html',
	styleUrls: ['./message-modal.component.scss'],
	imports: [IonicModule, TranslateModule, FormsModule, HeaderComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageModalComponent {
	@Input() public order: IOrders;

	public message: string;

	@ViewChild(IonModal) protected modal: IonModal;

	@Output() private readonly res = new EventEmitter();

	protected isPending = false;

	constructor(
		private readonly store: Store<IAppState>,
		private readonly toastService: ToastService,
		private readonly authService: AuthService,
		private readonly chatsService: ChatsService,
	) {}

	public cancel(): void {
		void this.modal.dismiss(null, 'cancel');
	}

	public async confirm(): Promise<void> {
		this.isPending = true;
		let chat_id = null;

		if (this.order?.user_id && this.authService?.principal?.id !== this.order.user_id) {
			const { data } = await this.chatsService.createOrGetChatIdAsync({
				user_1_id: this.authService.principal.id,
				user_2_id: this.order.user_id,
				is_group: false,
				chat_id: null,
			});

			chat_id = data?.chat_id;
		}

		this.store.dispatch(
			SendForm({
				formName: addMessage,
				formState: {
					formData: {
						message: this.message,
						order_id: this.order.id,
						send_sms: true,
						phone: this.order.phone,
						receiver: this.order.user_id,
						chat_id,
					},
				},
			}),
		);

		this.store.select(selectFormByName(addMessage)).subscribe(form => {
			if (form.hasResponse && form.responseData) {
				this.isPending = false;
				this.message = '';
				void this.modal.dismiss(null, 'confirm');
			}
		});
	}

	public onWillDismiss(event: Event): void {
		const ev = event as CustomEvent<OverlayEventDetail<string>>;
		if (ev.detail.role === 'confirm') {
			this.res.emit();
			void this.toastService.presentToast('YOUR_MESSAGE_SENT');
		}
	}
}
