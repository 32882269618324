import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { enUS, ru } from 'date-fns/locale'; // Импортируем нужные локали

@Pipe({
	name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
	constructor(private readonly translationService: TranslateService) {}
	public transform(value: Date | string): string {
		if (!value) return '';

		const date = new Date(value);
		const now = new Date();

		// Выбираем локализацию
		const currentLang = this.translationService.currentLang;

		const currentLocale = currentLang === 'ru' ? ru : enUS;

		// Форматирование времени в зависимости от того, насколько свежий этот момент
		const isToday = date.toDateString() === now.toDateString();
		const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === date.toDateString();

		if (isToday) {
			return format(date, 'HH:mm', { locale: currentLocale }); // "часы:минуты" или "hours:minutes"
		}

		if (isYesterday) {
			return `${currentLang === 'ru' ? 'Вчера' : 'Yesterday'}, ${format(date, 'HH:mm', { locale: currentLocale })}`;
		}

		const daysDifference = Math.floor((now.getTime() - date.getTime()) / (1000 * 3600 * 24));

		if (daysDifference < 7) {
			return format(date, 'eee, HH:mm', { locale: currentLocale }); // "день недели, часы:минуты" или "day of the week, hours:minutes"
		}

		return format(date, currentLang === 'ru' ? 'dd.MM' : 'MM/dd', { locale: currentLocale }); // "день.месяц" или "dd.MM"
	}
}
