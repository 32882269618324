import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMessage } from '@solar/core/src';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MessageReactionsService {
	constructor(private readonly http: HttpClient) {}

	public async addReaction(data: { message_id: number; reaction?: string }): Promise<IMessage> {
		try {
			return await lastValueFrom(this.http.post<IMessage>('message-reactions', data));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async deleteReaction(data: { message_id: number }): Promise<IMessage> {
		try {
			return await lastValueFrom(this.http.delete<IMessage>(`message-reactions/${data.message_id}`));
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
