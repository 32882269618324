import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@solar/core/src';
import { map } from 'rxjs/operators';
import { PagesEffects } from '../../store';
import { FormEffects } from '../forms';
import { LoadPage } from '../pages';
import { ChatsService } from './../../services/rest/chats/chats.service';

export const createGroupChat = 'createGroupChat';
export const editChat = 'editChat';
export const loadChats = 'loadChats';
export const createChat = 'createChat';
export const deleteChat = 'deleteChat';

@Injectable()
export class ChatsEffects {
	constructor(
		private readonly pagesEffects: PagesEffects,
		private readonly chatsService: ChatsService,
		private readonly formEffects: FormEffects,
		private readonly store: Store<IAppState>,
	) {}

	public readonly $loadChats = this.pagesEffects.getLoadPageEffect(loadChats, ({ reqData }) => {
		return this.chatsService.getAll(reqData);
	});

	public readonly $createChat = this.formEffects.getSendFormEffect(
		createChat,
		(formState: { formData: { user_1_id: number; user_2_id: number; is_group: boolean; chat_id: string } }) =>
			this.chatsService.createChat(formState.formData),
	);

	public readonly $editChat = this.formEffects.getSendFormEffect(
		editChat,
		(formState: { formData: { chat_name: string; chat_id: string; img?: File; description?: string } }) =>
			this.chatsService.editChat(formState.formData),
	);

	public readonly $createGroupChat = this.formEffects.getSendFormEffect(
		createGroupChat,
		(formState: { formData: { chat_name: string; members: number[]; img: File } }) => this.chatsService.createGroupChat(formState.formData),
	);

	public readonly $deleteChat = this.formEffects.getSendFormEffect(
		deleteChat,
		(formState: { formData: { chat_id: number; user_id: number } }) =>
			this.chatsService.deleteChat(formState.formData).pipe(
				map(() => {
					this.store.dispatch(LoadPage({ path: loadChats }));
				}),
			),
	);
}
