export interface IPrinter {
	id: number;
	title: string;
	printer_ip: string;
	address: string;
	vat_number: string;
	phone: string;
	user_id: number;
	website: string;
}
