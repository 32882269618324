import { OrderStatusesEnum } from '@solar/enums/order-statuses.enum';
import { IMaster } from '@solar/interfaces/masters';

export interface ICreateOrdersData {
	name: string;
	phone: string;
	code: string;
	wishes?: string;
	message_type: string;
	reminder: number;
	orders: ICreateOrders[];
}

export interface ICreateOrders extends IOrders {
	type: number;
	price: string;
	rent_time?: string;
	master_id: number;
}

export interface IOrders {
	client_user_name?: string;
	client_id?: number;
	client_img?: string;
	currency?: string;
	price_to?: string;
	rent_time?: string;
	service_time?: number | string;
	order_status_id?: OrderStatusesEnum;
	user_id?: number;
	master_id?: number;
	reminder?: string;
	schedule_name?: string;
	master?: IMaster;
	id?: number;
	title?: string;
	date: string;
	hours: string;
	name: string;
	phone: string;
	code: string;
	wishes?: string;
	price?: string | number;
	total_orders?: string;
	edited_date?: string | null;
}
