import { Injectable } from '@angular/core';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { UserRoleService } from '@solar/core/src/services/user-role/user-role.service';
import { CategoriesRes } from '@solar/core/src/store';

@Injectable({
	providedIn: 'root',
})
export class PrivilegeService {
	constructor(
		private readonly authService: AuthService,
		private readonly userRoleService: UserRoleService,
	) {}

	public privilegedUsers(category: CategoriesRes): boolean {
		const userId = this.authService?.principal?.id;

		if (!userId || (!category?.managers?.length && !category?.master?.id)) {
			return false;
		}

		if (this.userRoleService.isAdmin || category?.master?.id === userId) {
			return true;
		}

		return category?.managers?.includes(userId);
	}
}
