import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { from, mergeMap, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FirebaseAuthService, timeoutUtil, ToastService } from '../';
import { AuthService } from '../auth/auth.service';
import { ServerError } from '../types';

export interface InvalidParamDTO {
	name: string;
	reason: string[];
}

export interface ErrorDTO {
	fieldErrors?: InvalidParamDTO[];
	title?: string;
	type?: string;
}

const defaultServerError: ServerError = {
	message: 'Unknown error',
	fieldErrors: [],
};

const mapError = (error: ErrorDTO): ServerError => {
	return {
		message: error.title || 'Unknown error',
		fieldErrors: (error['fieldErrors'] || []).map(err => ({
			name: err.name,
			messages: err.reason,
		})),
	};
};

export enum API_ERROR_STATUSES {
	UNAUTHORIZED = 401,
	NO_PERMISSION = 403,
	SHOW_MESSAGE = 409,
	UNPROCESSABLE = 422,
	TO_MANY_REQUESTS = 429,
	SERVER_ERROR = 500,
}

const shouldShowErrorMessage = (status: API_ERROR_STATUSES): boolean => {
	return (
		status >= API_ERROR_STATUSES.SERVER_ERROR ||
		status === API_ERROR_STATUSES.SHOW_MESSAGE ||
		status === API_ERROR_STATUSES.TO_MANY_REQUESTS ||
		status === API_ERROR_STATUSES.UNAUTHORIZED ||
		status === API_ERROR_STATUSES.NO_PERMISSION ||
		status === API_ERROR_STATUSES.UNPROCESSABLE
	);
};

@Injectable()
export class ErrorsMapInterceptor implements HttpInterceptor {
	public readonly MAX_RETRY_ATTEMPTS = 3;
	constructor(private readonly injector: Injector) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(({ error, status }) => {
				const authService = this.injector.get(AuthService);
				const toastService = this.injector.get(ToastService);
				const fbService = this.injector.get(FirebaseAuthService);
				const router = this.injector.get(Router);

				const attempts = 0;

				const handleUnauthorized = async (attempts: number): Promise<void> => {
					return new Promise(async resolve => {
						if (attempts < this.MAX_RETRY_ATTEMPTS) {
							const firebaseToken = await fbService.getIdToken();
							if (firebaseToken === null) {
								await timeoutUtil();
								await handleUnauthorized(attempts + 1);
								resolve();
							} else {
								authService.loadAndSetUserData({
									...authService?.principal,
									token: firebaseToken,
								});
								resolve();
							}
						} else {
							// After 3 attempts, clear data
							void authService.logout();
							resolve();
						}
					});
				};
				// if user UNAUTHORIZED or token expired
				if ((+status as API_ERROR_STATUSES) === API_ERROR_STATUSES.UNAUTHORIZED && authService?.principal) {
					return from(handleUnauthorized(attempts)).pipe(
						mergeMap(() => {
							const updatedRequest = request.clone({
								setHeaders: {
									Authorization: `Bearer ${authService?.principal?.token}`,
								},
							});
							if (router.url === '/login') {
								void router.navigate(['/']);
							}
							return next.handle(updatedRequest);
						}),
					);
				}

				if (error?.message && error?.fieldErrors) {
					return throwError(error);
				}

				// route to server-error page
				if (shouldShowErrorMessage(status)) {
					void toastService.presentToast(error?.message || error?.title || error?.error, 2000, 'success');
				}

				return throwError({ error: error ? mapError(error) : defaultServerError });
			}),
		);
	}
}
