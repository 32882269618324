import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[appInputError]',
})
export class InputErrorDirective implements OnInit, OnDestroy {
	public static readonly ErrorClass = 'text-error';
	public static readonly ErrorTextClass = 'ion-margin-bottom';

	private readonly defaultMessages = {
		required: 'FORM.REQUIRED',
		email: 'FORM.EMAIL_ERROR',
	};

	@Input()
	public hasDefaultErrorBox = false;

	@Input('appInputError')
	public control: UntypedFormControl | any;

	@Input()
	public errorsMessages?: {
		required?: string;
		maxlength?: string;
		minlength?: string;
		pattern?: string;
	};

	public errorTextEl: HTMLElement;

	public hasShowError?: boolean;
	public currentErrorKey?: string;
	public currentMessage?: string;

	private readonly subscription: Subscription = new Subscription();

	constructor(
		private readonly el: ElementRef,
		private readonly renderer: Renderer2,
		private readonly translateService: TranslateService,
	) {}

	public ngOnInit(): void {
		this.createErrorTextEl();

		this.initListen();
	}

	public setCustomErrorMessages(): void {
		if (this.errorsMessages) {
			const currError = this.errorsMessages[this.currentErrorKey] as string;

			if (currError) {
				this.subscription.add(
					this.translateService.get(currError).subscribe((res: string) => {
						this.defaultMessages[this.currentErrorKey] = res;
					}),
				);
			}
		}
	}

	public createErrorTextEl(): void {
		this.errorTextEl = this.renderer.createElement('div') as HTMLElement;

		this.renderer.addClass(this.errorTextEl, InputErrorDirective.ErrorTextClass);
		this.renderer.addClass(this.errorTextEl, 'font-size-14');
		this.renderer.addClass(this.errorTextEl, 'color-danger');
		this.renderer.addClass(this.errorTextEl, 'line-height-normal');

		this.renderer.appendChild(this.el.nativeElement, this.errorTextEl);
	}

	public initListen(): void {
		if (this.control && !(this.control as FormGroup).controls) {
			this.subscription.add(
				(this.control as FormControl).statusChanges.subscribe(validStr => {
					if (validStr === 'INVALID' && this.hasShowError && !this.checkEqualError) {
						this.hasShowError = false;
					}
					if (validStr === 'INVALID' && !this.hasShowError) {
						this.showError();
						return;
					}

					if ((validStr === 'VALID' || validStr === 'PENDING') && this.hasShowError) {
						this.hideError();
					}
				}),
			);

			this.subscription.add(
				this.translateService.onLangChange.subscribe(() => {
					this.showCurrentMessage();
				}),
			);
		}
	}

	private showError(): void {
		const { errors, touched } = this.control as UntypedFormControl;

		if (touched) {
			this.currentMessage = this.getErrorMessage(errors);

			this.showCurrentMessage();

			this.renderer.addClass(this.el.nativeElement, InputErrorDirective.ErrorClass);

			this.hasShowError = true;
		}
	}

	private getErrorMessage(errors: ValidationErrors): string {
		this.currentErrorKey = Object.keys(errors)[0];
		const messageFromError = errors[this.currentErrorKey] as string;

		if (typeof messageFromError === 'string') {
			return messageFromError;
		}

		this.setCustomErrorMessages();

		return messageFromError[0] || this.defaultMessages[this.currentErrorKey] || 'ERROR';
	}

	private hideError(): void {
		this.currentMessage = '';
		this.showCurrentMessage();

		this.renderer.removeClass(this.el.nativeElement, InputErrorDirective.ErrorClass);

		this.hasShowError = false;
	}

	private showCurrentMessage(): void {
		if (this.currentMessage) {
			this.subscription.add(
				this.translateService.get(this.currentMessage).subscribe((res: string) => {
					this.errorTextEl.textContent = res;
				}),
			);
		} else {
			this.errorTextEl.textContent = '';
		}
	}

	private get checkEqualError(): boolean {
		const errorKey = Object.keys((this.control as UntypedFormControl).errors || {})[0];
		return this.currentErrorKey === errorKey;
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
