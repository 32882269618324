import { MasterInfo, UserRes } from '@solar/core/src/store';
import { CardModelsEnum } from '@solar/enums';

export interface CategoriesRes {
	managers_full?: UserRes[];
	manager?: UserRes;
	categories?: string;
	position?: string;
	master?: MasterInfo;
	id: number;
	img: string;
	is_active: boolean;
	title: string;
	title_ru: string;
	type: CategoryTypes;
	model?: CardModelsEnum;
	managers?: number[];
	services?: CategoryTypes;
}

export type CategoryTypes = 'nails' | 'makeup' | 'hair' | 'eyelashes' | 'brows' | 'lessons' | 'PEDICURE' | 'rent';
