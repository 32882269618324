interface IMappedCountryCodes {
    mask: string;
    label: string;
    value: string;
}
export interface IMappedPhoneCountryCodes extends IMappedCountryCodes {
    icon: string;
}

export interface IMappedUserCountryCodes extends IMappedCountryCodes {
    iso: string;
}
