import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { countryCodesMock } from '@solar/core/src';
import { HeaderComponent } from '../../../../components';

@Component({
	selector: 'hb-code-phone-countries',
	templateUrl: './code-phone-countries.component.html',
	styleUrls: ['./code-phone-countries.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, HeaderComponent, TranslateModule],
})
export class CodePhoneCountriesComponent implements OnInit {
	public countryCodes = countryCodesMock.map(it => ({
		mask: it.mask,
		icon: it.flag,
		label: it.code + ' (' + it.name + ')',
		value: it.code,
	}));

	public filtered: any[];

	public form: UntypedFormGroup = this.navParams.get('form');

	constructor(
		public modalCtrl: ModalController,
		private readonly navParams: NavParams,
	) {}

	public ngOnInit(): void {
		this.filtered = this.countryCodes;
	}

	public onDismiss(it): void {
		this.form.patchValue({
			code: it.value,
		});
		void this.modalCtrl.dismiss({
			...it,
			mask: String(it.mask).replace(/0/g, '*'),
		});
	}

	public filter(event): void {
		const filter = event.detail.value.toLowerCase();
		this.filtered = this.countryCodes.filter(item => this.leaf(item).toLowerCase().indexOf(filter) >= 0);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-unsafe-return
	public leaf = (obj: Object) => 'label'.split('.').reduce((value, el) => value[el], obj);

	public trackByFn(index: number, item: { id: number }): number {
		return item ? item.id : index;
	}
}
