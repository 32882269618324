import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isToday, isTomorrow } from 'date-fns';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';

@Directive({
	selector: '[hbFormatDate]',
	providers: [LocalizedDatePipe],
})
export class FormatDateDirective implements OnInit, OnChanges {
	@Input('hbFormatDate') public date!: string;

	private readonly todayTranslation: string;
	private readonly tomorrowTranslation: string;

	constructor(
		private readonly el: ElementRef<HTMLElement>,
		private readonly localizedDatePipe: LocalizedDatePipe,
		private readonly translate: TranslateService,
	) {
		this.todayTranslation = this.translate.instant('TODAY');
		this.tomorrowTranslation = this.translate.instant('TOMORROW');
	}

	public ngOnInit(): void {
		this.updateDateText();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		// Если дата изменилась, обновляем текст
		if (changes['date'] && !changes['date'].isFirstChange()) {
			this.updateDateText();
		}
	}

	private updateDateText(): void {
		const dateObj = new Date(this.date);

		let displayText = '';
		const formattedDate =
			this.localizedDatePipe.transform(dateObj, isToday(dateObj) || isTomorrow(dateObj) ? 'd MMM' : 'EEEE, d MMM') ?? '';

		if (isToday(dateObj)) {
			displayText = `${this.todayTranslation}, ${formattedDate}`;
		} else if (isTomorrow(dateObj)) {
			displayText = `${this.tomorrowTranslation}, ${formattedDate}`;
		} else {
			displayText = formattedDate;
		}

		if (this.el.nativeElement.textContent !== displayText) {
			this.el.nativeElement.textContent = displayText;
		}
	}
}
