import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class PruningTranslationLoader implements TranslateLoader {
    constructor(
        private readonly http: HttpClient,
        private readonly prefix: string = '/assets/i18n/',
        private readonly suffix: string = '.json?cb=' + new Date().getTime()
    ) {}

    public getTranslation(lang: string): any {
        return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(map((result: object) => this.process(result)));
    }

    private process(object: object): any {
        return Object.keys(object)
            .filter(key => object.hasOwnProperty(key) && object[key] !== '')
            .reduce(
                (result, key) => ((result[key] = typeof object[key] === 'object' ? this.process(object[key]) : object[key]), result),
                {}
            );
    }
}
