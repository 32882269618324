import { Injectable, signal } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../../auth/auth.service';
import { IMessage } from '../../interfaces';
import { timeoutUtil } from '../../utils';
import { MessagesStateService } from './../signals/messages-state/messages-state.service';

@Injectable({
	providedIn: 'root',
})
export class MessagesWsService {
	public readonly sendMessageSignal = signal<any>(null);
	public readonly typingMessageSignal = signal<any>(null);
	public usersInChat = signal<{ userId: number }[]>([]);

	public chatId = signal<string>('');
	public is_group = signal<boolean>(false);

	constructor(
		private readonly socket: Socket,
		private readonly messagesStateService: MessagesStateService,
		private readonly authService: AuthService,
	) {}

	public initialize(): void {
		this.setAllListeners();
		// Обработка события повторного подключения
		this.socket.on('connect', () => {
			if (this.chatId()) {
				this.addUserToChat(this.authService.principal.id);
			}
		});
	}

	public setAllListeners(): void {
		if (this.chatId()) {
			this.listenSendMessage();
			this.listenDeleteMessage();
			this.listenTyping();
			this.listenUsersInChat();
			this.listenIsReadUpdate();
		} else {
			console.log('Chat id is not set');
			void timeoutUtil(1000).then(() => {
				this.setAllListeners();
			});
		}
	}

	public joinChat(chatId: string): void {
		this.chatId.set(chatId);

		if (this.chatId()) {
			this.addUserToChat(this.authService.principal.id);
		}
	}

	public listenSendMessage(): void {
		this.socket.on('message', action => {
			this.addTyping(null);
			this.messagesStateService.addMessage(action.payload);
		});
	}

	public send(message: IMessage): void {
		this.socket.emit('send_message', message, this.chatId());
	}

	public listenDeleteMessage(): void {
		this.socket.on('delete_message_listener', action => {
			this.messagesStateService.delete(action.payload);
		});
	}

	public deleteMessage(messageId: number): void {
		this.socket.emit('delete_message', messageId, this.chatId());
	}

	public listenUsersInChat(): void {
		this.socket.on('users_in_chat', users => {
			if (Array.isArray(users)) {
				this.updateUsersInChat(users);
			} else {
				console.error('Received data is not an array:', users);
			}
		});
	}

	// Обновление состояния пользователей в чате
	private updateUsersInChat(users: { userId: number }[]): void {
		this.usersInChat.set(users); // Обновляем сигнал
	}

	// Проверка, находится ли пользователь в чате
	public isUserInChat(userId: number): boolean {
		return this.usersInChat().some(user => user.userId === userId);
	}

	public addUserToChat(userId: number): void {
		if (!this.isUserInChat(userId)) {
			this.usersInChat.update(users => [...users, { userId }]);
		}

		this.socket.emit('add_user_to_chat', this.chatId(), userId);
	}

	public removeUserFromChat(userId: number): void {
		this.socket.emit('remove_user_from_chat', this.chatId(), userId);
	}

	public listenTyping(): void {
		this.socket.on('typing', res => {
			this.typingMessageSignal.set(res);
		});
	}

	public addTyping(user): void {
		this.socket.emit('add_typing_messages', this.chatId(), user);
	}

	public listenIsReadUpdate(): void {
		this.socket.on('message_is_read_event', (action: { id: number; is_read: boolean }) => {
			const messages = this.messagesStateService.messages().data;

			const updatedData = messages.map(it => (it.id === action.id ? { ...it, ...action } : it));

			this.messagesStateService.messages.update(state => ({
				...state,
				data: updatedData,
			}));
		});
	}

	public setMessagesRead(messages: IMessage[]): void {
		this.socket.emit('messages_read', this.chatId(), messages);
	}

	public removeAllListeners(): void {
		this.removeUserFromChat(this.authService?.principal?.id);

		this.socket.removeAllListeners();
	}
}
