import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { IAppState } from '@solar/core/src';
import { selectFormByName } from '@solar/core/src/store/forms/selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'hb-chat-modal-files',
	templateUrl: './chat-modal-files.component.html',
	styleUrls: ['./chat-modal-files.component.scss'],
	imports: [IonicModule, TranslateModule, AsyncPipe, FormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatModalFilesComponent implements OnInit {
	private readonly activatedRoute = inject(ActivatedRoute);

	@Input() public imagePreviews: { webPath: string; file: File }[] = [];
	@Input() public isOpen = true;
	@Input() public formName: string;

	@Output() public closedEvent = new EventEmitter();

	public formIsPending$: Observable<boolean>;

	public message: string = '';

	constructor(
		private readonly ch: ChangeDetectorRef,
		private readonly store: Store<IAppState>,
	) {}

	public ngOnInit(): void {
		this.formIsPending$ = this.store.select(selectFormByName(this.formName)).pipe(map(r => !!r?.isPending));
	}

	public cancel(): void {
		this.closedEvent.emit();
	}

	public confirm(): void {
		this.closedEvent.emit({ files: this.imagePreviews, message: this.message });
		this.message = '';
	}

	public removePreview(index: number): void {
		// Удаляем изображение из массива
		this.imagePreviews.splice(index, 1);

		if (this.imagePreviews.length === 0) {
			this.cancel();
		}

		this.ch.detectChanges();
	}
}
