export type INearestTimeslots = INearestTimeslot[];

export interface INearestTimeslot {
	master_id: number;
	available_slots: AvailableSlot[];
}

export interface AvailableSlot {
	date: string;
	time: string[];
}
