import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlMasks } from '../enums';

export const HTTP_BASE_URL = new InjectionToken<string>('HTTP_BASE_URL');

const urlHasSchema = (url: string) => url && (url.startsWith('http://') || url.startsWith('https://'));
const isLocalizationRequest = (url: string) => url && url.includes('.json');

/**
 * Add base url to any relative requests url
 * HTTP_BASE_URL token must be provided.
 */
export class BaseUrlInterceptor implements HttpInterceptor {
	constructor(@Inject(HTTP_BASE_URL) private readonly httpBaseUrl: string) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!urlHasSchema(request.url) && !isLocalizationRequest(request.url)) {
			request = request.clone({
				url: Location.joinWithSlash(this.httpBaseUrl, request.url),
			});
		}

		request = this.replaceMasks(request);

		return next.handle(request);
	}

	public replaceMasks(req): HttpRequest<any> {
		if (req.url.includes(UrlMasks.UserId)) {
			const authData = JSON.parse(localStorage.getItem('principal'));

			if (!authData) {
				console.log('UrlInterceptor: not AuthData in LocalStorage');
			}

			req = req.clone({
				url: req.url.replace(UrlMasks.UserId, authData.id),
			});
		}

		return req;
	}
}
