import { IPagination } from '../pagination.interface';

export interface IFormState<T = any> {
	error?: any;
	responseData?: any;
	formData?: T;
	isPending?: boolean;
	isSuccess?: boolean;
	hasResponse?: boolean;
	isError?: boolean;
}

export const initFormState: IFormState = {
	error: null,
	formData: null,
	responseData: null,
	isPending: false,
	hasResponse: false,
	isSuccess: false,
	isError: false,
};

export interface IPayloadFormState {
	formName: string;
	formState: IFormState;
}

export const IFormInitialData = {
	formName: null,
	formState: null,
};

// FORMS END

export interface ILoadDataState<D> {
	firstLoading?: boolean;
	isPending?: boolean;
	hasLoaded?: boolean;
	isEmpty?: boolean;
	data?: D;
	items?: D;
	error?: any;
	pagination?: IPagination;
	message?: string;
}

export const InitialData = {
	isPending: false,
	isEmpty: false,
	hasLoaded: false,
	data: null,
	error: null,
};

export interface ICoreState {
	forms: any;
	pages: any;
}
