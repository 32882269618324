import { AsyncPipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { IAppState, IPaymentsItem } from '@solar/core/src';
import { getPaymentsList, getUser, LoadPage, selectPageByName } from '@solar/core/src/store';
import { SelectedPaymentEnum } from '@solar/enums/payments.enum';
import { HeaderComponent } from '@solar/shared/components/stateless/header/header.component';
import { take } from 'rxjs/operators';

@Component({
	selector: 'hb-payments-list-modal',
	templateUrl: './payments-list-modal.component.html',
	styleUrls: ['./payments-list-modal.component.scss'],
	imports: [IonicModule, AsyncPipe, LowerCasePipe, TitleCasePipe, TranslateModule, HeaderComponent],
})
export class PaymentsListModal implements OnInit {
	@Input() public items: IPaymentsItem[] = [];
	@Input() public selectedItem: string;
	@Input() public title = 'Payment methods';

	@Input() public otherPaymentMethods = true;

	@Output() public selectionCancel = new EventEmitter<void>();
	@Output() public selectionChange = new EventEmitter<IPaymentsItem>();

	public paymentsList$ = this.store.select(selectPageByName(getPaymentsList));

	protected selected: IPaymentsItem;

	protected cards: IPaymentsItem[] = [];

	constructor(private readonly store: Store<IAppState>) {}

	public ngOnInit(): void {
		this.store
			.select(selectPageByName(getUser))
			.pipe(take(1))
			.subscribe(user => {
				if (user?.data && user?.data?.customer_id) {
					this.store.dispatch(LoadPage({ path: getPaymentsList, reqData: { customerId: user?.data?.customer_id } }));
				}
			});

		this.paymentsList$.subscribe(res => {
			if (res?.data) {
				this.cards = res.data.map(it => ({
					icon: it?.card?.brand,
					value: it?.id,
					text: '**** **** **** ' + it?.card?.last4,
					payment_method: it?.id,
				}));
			}
		});
	}

	public trackItems(index: number, item: IPaymentsItem): string {
		return item.value;
	}

	public cancelChanges(): void {
		this.selectionCancel.emit();
	}

	protected onSelected(item: IPaymentsItem): void {
		this.selected = item;
	}

	protected onSelectedByValue(value: string): void {
		this.selected = this.items.find(it => it.value === value);
	}

	public confirmChanges(): void {
		const newCardItem: IPaymentsItem = { icon: 'card-outline', value: SelectedPaymentEnum.CARD, text: 'CARD' };
		const selected: IPaymentsItem = [newCardItem, ...this.items, ...this.cards].find(it => it.value === this.selectedItem);

		this.selectionChange.emit(this.selected ? this.selected : selected);
	}

	public checkboxChange(ev): void {
		const { value } = ev.detail;
		if (value) {
			this.selectedItem = value;
		}
	}

	protected readonly SelectedPaymentEnum = SelectedPaymentEnum;
}
