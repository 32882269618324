import { HttpErrorResponse } from '@angular/common/http';
import { CreateEffectMetadata } from '@ngrx/effects';
import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';

export const LoadBatchPage = createAction('[Batch Page] Loading', props<{ path: string; filters?: any; reqData?: any }>());
export const LoadBatchPageSuccess = createAction('[Batch Page] Loaded success', props<{ path: string; data: any }>());
export const LoadBatchPageError = createAction(
	'[Batch Page] Loading fail',
	props<{ path: string; error: HttpErrorResponse; data?: any }>(),
);

export const LoadPage = createAction('[Page] Loading', props<{ path: string; filters?: any; reqData?: any }>());
export const LoadPageSuccess = createAction('[Page] Loaded success', props<{ path: string; data: any }>());
export const LoadPageError = createAction('[Page] Loading fail', props<{ path: string; error: HttpErrorResponse; data?: any }>());

export const LoadPagePush = createAction('[Page Push] Loading..', props<{ path: string; page: number; reqData?: any }>());
export const LoadPagePushSuccess = createAction('[Page Push] Success', props<{ path: string; data: any; page?: number; pages?: number }>());

export const ClearPage = createAction('[Page] Clear', props<{ path: string }>());

export const ClearAllPages = createAction('Clear all Pages');

export type ILoadPagePushEffect = Observable<ReturnType<typeof LoadPageError>> & CreateEffectMetadata;

export type ILoadPageEffect = Observable<ReturnType<typeof LoadPageSuccess>> & CreateEffectMetadata;

export type ISendFormEffect = Observable<{ formName: string; responseData: any } & { type: '[FORM] Send Success' }> & CreateEffectMetadata;
