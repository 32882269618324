export interface ICountryCode {
    mask: string;
    icon: string;
    label: string;
    value: string;
}

export interface ICountryCodesMap {
    [key: string]: ICountryCode[];
}
