import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stripDomain',
})
export class StripDomainPipe implements PipeTransform {
	public transform(url: string): string {
		try {
			return new URL(url).pathname;
		} catch (error) {
			// Если URL некорректен, возвращаем исходную строку или пустую строку
			console.error('Invalid URL:', error);
			return url;
		}
	}
}
