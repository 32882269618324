import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { PushSubsService } from '../';

@Injectable({
	providedIn: 'root',
})
export class FcmService {
	private currentFcmToken: string | null = null;

	public get fcmToken(): string | null {
		return this.currentFcmToken;
	}

	constructor(
		private readonly pushSubsService: PushSubsService,
		private readonly platform: Platform,
		private readonly router: Router,
	) {}

	public initPush(): void {
		if (this.platform.is('capacitor')) {
			this.registerPush();
		}
	}

	public registerPush(): void {
		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		void PushNotifications.requestPermissions().then(result => {
			if (result.receive === 'granted') {
				// Register with Apple / Google to receive push via APNS/FCM
				void PushNotifications.register();
			} else {
				// Show some error
			}
		});

		// On success, we should be able to receive notifications
		void PushNotifications.addListener('registration', async (token: Token) => {
			console.log('Push registration success, ----TOKEN------->: ' + token.value);
			this.currentFcmToken = token.value;

			await this.pushSubsService.addFCM({ fcm_token: token.value });
		});

		// Some issue with our setup and push will not work
		void PushNotifications.addListener('registrationError', (error: any) => {
			console.log('Error on registration: ' + JSON.stringify(error));
		});

		// Show us the notification payload if the app is open on our device
		void PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
			console.log('Push received: ' + JSON.stringify(notification));

			const intercom_push_type = notification?.data?.intercom_push_type;
			if (intercom_push_type) {
				console.log('some messages');
			}
		});

		// Method called when tapping on a notification
		void PushNotifications.addListener('pushNotificationActionPerformed', async (action: ActionPerformed) => {
			const category = action?.notification?.data?.aps?.category;

			if (category) {
				void this.router.navigateByUrl(category);
			}
		});
	}

	public async deleteFcm(): Promise<void> {
		if (this.fcmToken) {
			await this.pushSubsService.deleteFCM(this.fcmToken);
		}
	}
}
