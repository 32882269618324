import { CommonModule } from '@angular/common';
import { NgModule, importProvidersFrom } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../../../src/environments/environment';
import { AuthModule } from './auth';
import { AuthServiceConfig } from './auth/auth.service';
import { ICoreConfig } from './core.interface';
import { ENV, INDEX_URL } from './core.tokens';
import {
	AddressesEffects,
	BalancesEffects,
	CategoryServicesEffects,
	CloseDayEffects,
	DescriptionsEffects,
	FormEffects,
	LoginEffects,
	MainSliderEffects,
	MastersEffects,
	MessagesEffects,
	OrdersEffects,
	PagesEffects,
	PasswordResetEffects,
	PaymentsEffects,
	ReviewsEffects,
	SignUpEffects,
	SlidesEffects,
	SmsCodesEffects,
	UserEffects,
	WorkingHoursEffects,
	PrintersEffects,
} from './store';
import { BatchEffects } from './store/batch';
import { CategoriesEffects } from './store/categories';
import { ChatsEffects } from './store/chats/chats.effects';
import { reducers } from './store/main';
const urlObject = new URL(environment.api);
const secureProtocol = urlObject.protocol === 'https:' ? 'wss:' : 'ws:';
const socketIoConfig: SocketIoConfig = { url: `${secureProtocol}//${urlObject.host}`, options: { transports: ['websocket'] } };
const config: ICoreConfig = {
	indexUrl: '/',
	env: environment,
};

const authConfig: AuthServiceConfig = {
	baseUrl: environment.api,
	storageTokenKey: 'token',
	storagePrincipalKey: 'principal',
};

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		AuthModule.forRoot(authConfig),
		StoreModule.forFeature('core', reducers),
		EffectsModule.forFeature([
			FormEffects,
			PagesEffects,
			CategoryServicesEffects,
			UserEffects,
			CategoriesEffects,
			OrdersEffects,
			SmsCodesEffects,
			SlidesEffects,
			ReviewsEffects,
			MessagesEffects,
			WorkingHoursEffects,
			PaymentsEffects,
			DescriptionsEffects,
			BatchEffects,
			MastersEffects,
			CloseDayEffects,
			LoginEffects,
			PasswordResetEffects,
			SignUpEffects,
			BalancesEffects,
			AddressesEffects,
			ChatsEffects,
			MainSliderEffects,
			PrintersEffects,
		]),
		TranslateModule,
	],
	providers: [
		{
			provide: ENV,
			useValue: config.env,
		},
		{
			provide: INDEX_URL,
			useValue: config.indexUrl,
		},
		importProvidersFrom(SocketIoModule.forRoot(socketIoConfig)),
	],
})
export class HamboCoreModule {}
