import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IReceiptData } from '@solar/shared';
import { formatISO } from 'date-fns';
import { lastValueFrom, Observable } from 'rxjs';
import { IPrinter } from './printers.dto';

@Injectable({
	providedIn: 'root',
})
export class PrintersService {
	constructor(private readonly http: HttpClient) {}

	public addPrinter(body: IPrinter): Observable<IPrinter> {
		return this.http.post<IPrinter>(`/printers`, body);
	}

	public editPrinter(body: IPrinter): Observable<IPrinter> {
		return this.http.patch<IPrinter>(`/printers`, body);
	}

	public async getPrintersAsync(): Promise<IPrinter[]> {
		return await lastValueFrom(this.http.get<IPrinter[]>(`/printers`));
	}

	public async printTheBill(items: IReceiptData[]): Promise<{ message: string; results: { receiptData: Uint8Array; printer: string }[] }> {
		const clientTime = formatISO(new Date());

		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		return await lastValueFrom(
			this.http.post<{ message: string; results: { receiptData: Uint8Array; printer: string }[] }>(`/printers/bill`, {
				items,
				clientTime,
				timezone,
			}),
		);
	}

	public async deletePrintersAsync(id: number): Promise<IPrinter[]> {
		return await lastValueFrom(this.http.delete<IPrinter[]>(`/printers/${id}`));
	}
}
