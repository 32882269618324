import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';
import { CategoryServicesRes } from '../../../store/category-services';

@Injectable({
	providedIn: 'root',
})
export class CategoryServicesService {
	constructor(private readonly http: HttpClient) {}

	public getAll(masterId: number, type?: string): Observable<ICollectionResponse<CategoryServicesRes>> {
		let url = `master-services/${masterId}`;
		if (type) {
			url += `/${type}`;
		}
		return this.http.get<ICollectionResponse<CategoryServicesRes>>(url);
	}

	public addService(body: CategoryServicesRes): Observable<CategoryServicesRes> {
		return this.http.post<CategoryServicesRes>('master-services', this.formData(body));
	}

	public editService(body: CategoryServicesRes): Observable<CategoryServicesRes> {
		return this.http.patch<CategoryServicesRes>(`master-services/${body.id}`, this.formData(body));
	}

	public async editServicesPositionsAsync(body: CategoryServicesRes[]): Promise<{ message: string }> {
		try {
			return await lastValueFrom(this.http.post<{ message: string }>(`master-services-positions`, body));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public async deleteService(id: number): Promise<Object> {
		try {
			return await lastValueFrom(this.http.delete(`master-services/${id}`));
		} catch (e) {
			console.log(e);
		}
	}

	protected formData(body: CategoryServicesRes): FormData {
		const formData = new FormData();
		formData.append('master_id', body?.master_id?.toString());
		formData.append('price_to', body.price_to);
		formData.append('category_id', body?.category_id?.toString());
		formData.append('price', body.price);
		formData.append('masters', body?.masters?.toString());
		formData.append('service_time', body.service_time);
		formData.append('img', body.img);
		formData.append('title', body.title);
		formData.append('title_ru', body.title_ru);
		formData.append('type', body.title);
		formData.append('position', `${body.position}`);
		formData.append('description', body?.description);
		formData.append('currency', body?.currency);
		formData.append('stock_available', `${body?.stock_available}`);

		return formData;
	}
}
