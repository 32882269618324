export interface IChat {
	description?: string;
	id: number;
	user_id: number;
	img?: string;
	name?: string;
	is_read: boolean;
	created_at?: Date;
	chat_id?: string;
	user_name?: string;
	first_name?: string;
	last_name?: string;
	is_group?: boolean;
	member_count?: number;
	chat_name?: string;
}
