// src/app/services/language.service.ts
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

@Injectable({
	providedIn: 'root',
})
export class DeviceLanguageService {
	constructor() {}

	// Метод для получения языка
	public async getDeviceLanguage(): Promise<string> {
		const platform = Capacitor.getPlatform();

		if (platform === 'web') {
			// Для веба используем navigator
			return this.getBrowserLanguage();
		} else {
			// Для iOS/Android используем плагин Device
			const info = await Device.getLanguageCode();
			return info.value;
		}
	}

	// Метод для получения языка в вебе
	private getBrowserLanguage(): string {
		return navigator.language;
	}
}
