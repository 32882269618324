import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { INearestTimeslot } from './nearest-timeslot.dto';
@Injectable({
	providedIn: 'root',
})
export class NearestTimeslotService {
	constructor(private readonly http: HttpClient) {}

	public async getDateAndTime(body: { masters: number[]; service_time: string }): Promise<INearestTimeslot[]> {
		return await lastValueFrom(this.http.post<INearestTimeslot[]>(`orders/nearest-timeslot`, body));
	}
}
