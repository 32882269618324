import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';
import { CategoriesRes } from '../../../store/categories';

@Injectable({
	providedIn: 'root',
})
export class DescriptionsService {
	constructor(private readonly http: HttpClient) {}

	public add(body: { text: string; parent_id: number }): Observable<[]> {
		return this.http.post<[]>('/descriptions', body);
	}

	public edit(body: { text: string; id: number }): Observable<[]> {
		return this.http.patch<[]>(`descriptions`, body);
	}

	public async delete(id: number): Promise<Object> {
		try {
			return await lastValueFrom(this.http.delete(`descriptions/${id}`));
		} catch (e) {
			console.log(e);
		}
	}

	public async patch(body: { text: string; id: number }): Promise<Object> {
		try {
			return await lastValueFrom(this.http.patch(`descriptions/${body.id}`, body));
		} catch (e) {
			console.log(e);
		}
	}

	public loadAll(req?: { parent_id?: number }): Observable<ICollectionResponse<CategoriesRes>> {
		return this.http.get<ICollectionResponse<CategoriesRes>>(`descriptions/all/${req.parent_id}`);
	}

	public loadOne(id: number): Observable<ICollectionResponse<CategoriesRes>> {
		return this.http.get<ICollectionResponse<CategoriesRes>>(`descriptions/${id}`);
	}
}
