import { HttpErrorResponse } from '@angular/common/http';
import { CreateEffectMetadata } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { IPagination } from '../pagination.interface';

// Используем встроенные типы с ReturnType вместо TypedAction
export type ILoadDashboardBatch = {
	path: string;
	filters?: any;
	reqData?: number | undefined;
	type: '[Page] Loading';
};

export type ICheckFormError = {
	formName: string;
	error: HttpErrorResponse;
	type: '[FORM] Send Error';
};

export type ICheckFormSuccess = {
	formName: string;
	responseData: any;
	type: '[FORM] Send Success';
};

export type ICheckCompleteUpload = {
	formName: string;
	responseData: any;
	type: '[FORM] Send Success';
};

export type IFreelancerSignUp = { type: 'Clear store' } | { formName: string; type: '[FORM] Reset' };

export type ITaxStatusChangeFormError = {
	formName: string;
	error: HttpErrorResponse;
	type: '[FORM] Send Error';
};

export type ITaxStatusChangeFormSuccess = {
	formName: string;
	responseData: string;
	type: '[FORM] Send Success';
};

export type ILoadPagePushEffect = Observable<
	| {
			path: string;
			error: HttpErrorResponse;
			data?: any;
			type: '[Page] Loading fail';
	  }
	| {
			path: string;
			data: any;
			pagination: IPagination;
			type: any; // Если возможно, уточните тип здесь
	  }
> &
	CreateEffectMetadata;

export type ILoadPageEffect = Observable<
	| {
			path: string;
			data: any;
			type: '[Page] Loaded success';
	  }
	| {
			path: string;
			error: HttpErrorResponse;
			data?: any;
			type: '[Page] Loading fail';
	  }
> &
	CreateEffectMetadata;

export type ISendFormEffect = Observable<
	| {
			formName: string;
			responseData: any;
			type: '[FORM] Send Success';
	  }
	| {
			formName: string;
			error: HttpErrorResponse;
			type: '[FORM] Send Error';
	  }
> &
	CreateEffectMetadata;
