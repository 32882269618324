import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateService, HTTP_BASE_URL, IAppState, ToastService } from '@solar/core/src';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { LoadPage, SendForm } from '@solar/core/src/store';
import { categoriesPageName } from '@solar/core/src/store/categories';
import { addMasterFormName, editMasterFormName } from '@solar/core/src/store/masters/masters.effects';
import { UserRes } from '@solar/core/src/store/user/user.res';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { CodePhoneComponent } from '../code-phone/code-phone.component';
import { ServicesSelectComponent } from '../services-select/services-select.component';

import { IonicModule } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { BirthdayComponent } from '@solar/shared/form-components/statefull/birthday/birthday.component';
import { FormConnectDirective } from '../../../directives/form-connect.directive';

@Component({
	selector: 'hb-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['./user-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		FormConnectDirective,
		IonicModule,
		CodePhoneComponent,
		ServicesSelectComponent,
		TranslateModule,
		BirthdayComponent,
	],
})
export class UserFormComponent implements OnInit {
	public form: FormGroup;

	public formName: string;

	public imagePreviewUrl: ArrayBuffer | string;

	@Input() public master: UserRes;

	@Input() public showServices = false;
	@Input() public role: UserRolesEnum;

	@Output() public successEmit: EventEmitter<void> = new EventEmitter();

	@Input() showBirthdayComponent = true;

	constructor(
		private readonly store: Store<IAppState>,
		private readonly fb: FormBuilder,
		private readonly ch: ChangeDetectorRef,
		public readonly authService: AuthService,
		@Inject(HTTP_BASE_URL) private readonly httpBaseUrl: string,
		protected readonly dateService: DateService,
		private readonly translate: TranslateService,
		private readonly toastService: ToastService,
	) {}

	public async takePicture(): Promise<void> {
		const image = await Camera.getPhoto({
			quality: 100,
			allowEditing: true,
			resultType: CameraResultType.Base64,
			source: CameraSource.Prompt,
			promptLabelHeader: this.translate.instant('Photo'),
			promptLabelCancel: this.translate.instant('CLOSE'),
			promptLabelPhoto: this.translate.instant('Photo library'),
			promptLabelPicture: this.translate.instant('Take photo'),
		});

		const base64Blob = this.dataURItoBlob(`data:image/png;base64,${image.base64String}`);

		const file = new File([base64Blob], 'image.png', { type: 'image/png' });

		this.form.get('img').setValue(file);

		this.imagePreviewUrl = `data:image/png;base64,${image.base64String}`;

		this.ch.detectChanges();
		this.form.markAsDirty();
	}

	private dataURItoBlob(dataURI: string): Blob {
		const byteString = atob(dataURI.split(',')[1]);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		return new Blob([int8Array], { type: 'image/png' });
	}

	public ngOnInit(): void {
		this.store.dispatch(LoadPage({ path: categoriesPageName }));

		this.form = this.fb.group({
			id: [''],
			user_name: ['', Validators.required],
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			code: ['', Validators.required],
			phone: ['', Validators.required],
			categories: [''],
			role: ['', Validators.required],
			img: [''],
			birth_date: [''],
		});

		this.setEditConfigs();
		this.setRoleToForm();
	}

	public setRoleToForm(): void {
		if (this.role) {
			this.form.get('role').setValue(this.role);
		}
	}

	public setEditConfigs(): void {
		if (this.master) {
			this.formName = editMasterFormName;
			const slicedCode = this.master?.code ? this.master.phone.slice(this.master.code.length) : this.master.phone;

			this.form.patchValue({
				id: this.master.id,
				user_name: this.master.user_name,
				first_name: this.master.first_name,
				last_name: this.master.last_name,
				code: this.master?.code,
				phone: slicedCode,
				categories: this.master.categories,
				role: String(this.master.role).toLowerCase(),
				img: this.master.img,
				birth_date: this.master?.birth_date ? this.dateService.dateTranslate(this.master?.birth_date) : null,
			});

			if (this.master?.code) {
				this.form.get('code').setValue(this.master?.code);
			}

			this.imagePreviewUrl = this.master.img ? `${this.httpBaseUrl}/${this.master.img}` : null;
		} else {
			this.formName = addMasterFormName;
		}
	}

	public onSuccessForm(ev): void {
		this.successEmit.emit(ev);
	}

	public onWillDismiss(event: Event): void {
		const ev = event as CustomEvent<OverlayEventDetail<string>>;

		if (ev.detail.role === 'confirm') {
			const date = this.dateService.dateTranslate(ev.detail.data);
			this.form.get('birth_date').setValue(date);
		}
	}

	public submitForm(): void {
		if (this.form.valid) {
			this.store.dispatch(
				SendForm({
					formName: this.formName,
					formState: {
						formData: this.form.value,
					},
				}),
			);
		} else {
			void this.toastService.presentToast('PLEASE_FILL_ALL_REQUIRED_FIELDS', 1500, 'error');
		}
	}

	protected readonly UserRolesEnum = UserRolesEnum;
}
