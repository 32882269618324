import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceLanguageService } from '@solar/core/src/services/device-language/device-language.service';
import { TranslateRestService } from '../../../../../core/src/services/rest/translate-rest/translate-rest.service';

@Component({
	selector: 'hb-see-translate',
	templateUrl: './see-translate.component.html',
	styleUrls: ['./see-translate.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, TranslateModule],
})
export class SeeTranslateComponent {
	@Input() public text: string;

	protected translatedText: string = '';

	protected loading: boolean = false;

	constructor(
		private readonly translateRest: TranslateRestService,
		private readonly deviceLanguage: DeviceLanguageService,
		private readonly ch: ChangeDetectorRef,
	) {}

	protected async translateText(): Promise<void> {
		if (this.translatedText) {
			this.translatedText = '';
			this.ch.markForCheck();
			return;
		}

		this.loading = true;
		const lang = await this.deviceLanguage.getDeviceLanguage();
		const res = await this.translateRest.load({ text: this.text, lang });

		if (res?.text) {
			this.translatedText = res?.text;

			this.loading = false;
		}

		this.ch.markForCheck();
	}
}
