import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderStatusesEnum } from '@solar/enums/order-statuses.enum';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OrdersService {
	constructor(private readonly http: HttpClient) {}

	public async checkoutService(body: { price_id: string }): Promise<object> {
		try {
			return await lastValueFrom(this.http.post(`orders-checkout-session`, body));
		} catch (e) {
			console.log(e);
		}
	}
	public async createOrderPayment(body: { phone: string; name: string; token: string; amount: number }): Promise<object> {
		try {
			return await lastValueFrom(
				this.http.post(`orders-payment`, {
					name: 'enzo',
					phone: '+380997077673',
					amount: 15000,
				}),
			);
		} catch (e) {
			console.log(e);
		}
	}
	public async createOrder(body): Promise<object> {
		try {
			return await lastValueFrom(this.http.post(`orders`, body));
		} catch (e) {
			console.log(e);
		}
	}

	public async orderAction(id: number, status_id: OrderStatusesEnum): Promise<object> {
		try {
			return await lastValueFrom(this.http.patch(`orders/action/${id}`, { status_id }));
		} catch (e) {
			console.log(e);
		}
	}

	public async getAllOrders(body?: { master_id?: number | string; date?: string; type?: string; user_id?: number }): Promise<object> {
		try {
			return await lastValueFrom(this.http.get(`orders`, { params: body }));
		} catch (e) {
			console.log(e);
		}
	}

	public async getOrderDateHours(body: { date: string; master_id: number }): Promise<{ data: { hours: string }[] }> {
		try {
			return await lastValueFrom(this.http.get<{ data: { hours: string }[] }>(`order-date-hours`, { params: body }));
		} catch (e) {
			console.log(e);
		}
	}

	public async getMastersOrderDateHours(body: {
		date: string;
		masters_id: number[];
		except_order_status?: OrderStatusesEnum;
	}): Promise<{ data: { hours: string }[] }> {
		try {
			return await lastValueFrom(this.http.post<{ data: { hours: string }[] }>(`order-masters-date-hours`, body));
		} catch (e) {
			console.log(e);
		}
	}

	public async getMastersOrderDateHoursFullMonth(body: {
		masters_id: number[];
		date?: string;
		except_order_status?: OrderStatusesEnum;
	}): Promise<{ data: { date: string; hours: string }[] }> {
		try {
			if (!body?.date) {
				body.date = new Date().toString();
			}
			return await lastValueFrom(this.http.post<{ data: { date: string; hours: string }[] }>(`order-masters-date-hours-full-month`, body));
		} catch (e) {
			throw e;
		}
	}

	public async getOrderDateHoursStartAndEnd(body: { start_of_month: string; end_of_month: string; master_id: string }): Promise<object> {
		try {
			return await lastValueFrom(this.http.get(`/order-date-hours-start-end`, { params: body }));
		} catch (e) {
			console.log(e);
		}
	}
}
