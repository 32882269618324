import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { isEmptyData } from '@solar/core/src/services/utils';
import { IAppState } from '@solar/interfaces';
import { first } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class StoreServiceUtil {
	constructor(private readonly store: Store<IAppState>) {}

	public dispatchIfEmptyMultiple(actions: { path: string; action: Action }[]): void {
		actions.forEach(({ path, action }) => setTimeout(() => this.dispatchIfEmpty(path, action)));
	}

	public dispatchIfEmpty(path: string, action: Action): void {
		const data = this.getStateByPath(path);

		if (isEmptyData(data)) {
			this.store.dispatch(action);
		}
	}
	public getStateByPath(path: string): any {
		let returnState;

		this.store.pipe(first()).subscribe(state => {
			// Используем split для разделения пути и reduce для доступа к вложенным свойствам
			returnState = path.split('.').reduce((acc, part) => acc?.[part], state);
		});

		return returnState;
	}

	public isEmptyStoreEmit(path: string): boolean {
		const data = this.getStateByPath(path);

		return isEmptyData(data);
	}

	public dispatchMultipleActions(actions: Action[]): void {
		actions && actions.forEach(action => action && this.store.dispatch(action));
	}
}
