import { Pipe, PipeTransform } from '@angular/core';

export interface IFullNamePipeItem {
	first_name?: string;
	last_name?: string;
}

@Pipe({
	name: 'fullName',
	pure: true,
})
export class FullNamePipe implements PipeTransform {
	public transform(obj: IFullNamePipeItem, revert: boolean = false): string {
		if (obj) {
			return revert ? `${obj.last_name} ${obj.first_name}` : `${obj.first_name} ${obj.last_name ? obj.last_name : ''}`;
		} else {
			return '';
		}
	}
}
