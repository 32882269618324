import { UserRolesEnum } from '@solar/enums/user-roles.enum';

export interface LoginData {
	method: string;
	password: string;
}

export interface Principal {
	printer_active?: boolean | null;
	id: number;
	role: UserRolesEnum;
	token: string;
	img?: string;
	first_name?: string;
	last_name?: string;
	user_name?: string;
}
