import { SelectedPaymentEnum } from '@solar/enums/payments.enum';

export interface IPaymentsItem {
    payment_method?: string;
    text: string;
    value: SelectedPaymentEnum;
    icon: string;
}

export interface IPaymentData {
    name: string;
    phone: string;
    amount?: number;
    currency: string;
    payment_method?: string;
    master_id?: number;
}
