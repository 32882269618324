import { createReducer, on } from '@ngrx/store';
import {
	ClearAllPages,
	ClearPage,
	LoadBatchPage,
	LoadBatchPageError,
	LoadBatchPageSuccess,
	LoadPage,
	LoadPageError,
	LoadPagePush,
	LoadPagePushSuccess,
	LoadPageSuccess,
} from './actions';
import { IPagesState } from './interfaces';
import { getStoredPath } from './utils';

interface PathState {
	hasLoaded: boolean;
	isPending: boolean;
	[key: string]: any;
}

interface ParsedObject {
	[key: string]: any;
}

function parseJsonStrings(obj: ParsedObject): ParsedObject {
	for (const key in obj) {
		if (typeof obj[key] === 'string') {
			try {
				const value = JSON.parse(obj[key]);
				if (value?.data) {
					obj[key] = {
						path: key,
						pathState: {
							...value,
							hasLoaded: true,
							isPending: false,
						} as PathState,
					};
				}
			} catch (e) {
				// Игнорируем ошибку, если строка не является JSON
			}
		} else if (typeof obj[key] === 'object' && obj[key] !== null) {
			parseJsonStrings(obj[key]);
		}
	}
	return obj;
}

export const PagesReducers = createReducer(
	{} as IPagesState<any>,
	on(LoadPage, (state, { path }) => {
		return {
			...state,
			[path]: {
				path,
				pathState: {
					...getStoredPath(path),
					...(state[path]?.pathState || {}), // Заменили get на опциональную цепочку
					hasLoaded: false,
					isPending: true,
				},
			},
		};
	}),
	on(LoadPageSuccess, (state, { path, data }) => {
		return {
			...state,
			[path]: {
				path,
				pathState: {
					...(state[path]?.pathState || {}), // Заменили get на опциональную цепочку
					...data,
					hasLoaded: true,
					isPending: false,
				},
			},
		};
	}),
	on(LoadPageError, (state, { path, error, data }) => {
		return {
			...state,
			[path]: {
				path,
				pathState: {
					...(state[path]?.pathState || {}), // Заменили get на опциональную цепочку
					...error,
					data,
					hasLoaded: true,
					isPending: false,
				},
			},
		};
	}),

	// PUSHING
	on(LoadPagePush, (state, { path }) => ({
		...state,
		[path]: {
			path,
			pathState: {
				...(state[path]?.pathState || {}), // Заменили get на опциональную цепочку
				hasLoaded: false,
			},
		},
	})),
	on(LoadPagePushSuccess, (state, { path, data, page, pages }) => {
		return {
			...state,
			[path]: {
				path,
				pathState: {
					...(state[path]?.pathState || {}), // Заменили get на опциональную цепочку
					data: [...(state[path]?.pathState?.data || []), ...data], // Заменили get на опциональную цепочку
					hasLoaded: true,
					isPending: false,
					page,
					pages,
				},
			},
		};
	}),
	on(LoadBatchPage, state => {
		const parsedData = parseJsonStrings({ ...localStorage });
		return {
			...state,
			...parsedData,
		};
	}),
	on(LoadBatchPageSuccess, (state, { path, data }) => {
		const arr = data.map(item => ({
			[item.id]: {
				path: item.id,
				pathState: {
					...(state[path]?.pathState || {}), // Заменили get на опциональную цепочку
					data: item.data?.data ? item.data.data : item.data,
					hasLoaded: true,
					isPending: false,
				},
			},
		}));
		const mergedObject = Object.assign({}, ...arr);
		return {
			...state,
			...mergedObject,
		};
	}),
	on(LoadBatchPageError, (state, { path, error, data }) => {
		return {
			...state,
			[path]: {
				path,
				pathState: {
					...(state[path]?.pathState || {}), // Заменили get на опциональную цепочку
					...error,
					data,
					hasLoaded: true,
					isPending: false,
				},
			},
		};
	}),

	on(ClearPage, (state, { path }) => ({
		...state,
		[path]: {
			path,
			pathState: {},
		},
	})),

	on(ClearAllPages, (): any => ({})),
);
