<ion-header mode="ios">
	<ion-toolbar>
		<ion-searchbar
			class="ion-margin-top"
			(ionInput)="handleInput($event)"
			(ionClear)="onSearchClear()"
			[placeholder]="'SEARCH' | translate"
		></ion-searchbar>
	</ion-toolbar>
</ion-header>

<div class="flex-row flex-wrap ion-justify-content-between p-10">
	@for (item of filteredEmojis; track $index) {
		<ion-button size="small" fill="clear" class="emoji" [class.selected]="selectedIndex === $index" (click)="setSelectedEmoji($index)">
			<div class="font-size-25">
				{{ item.value }}
			</div>
		</ion-button>
	}
</div>
<!-- <hb-gallery-slides
    [slides]="(slides$ | async)?.data"
    [selectedIndex]="selectedIndex"
    (setOpenEvent)="setOpen($event)"
  ></hb-gallery-slides> -->
