<form class="ion-margin-top" [formConnect]="formName" [formGroup]="form" (onSuccessForm)="onSuccessForm($event)" (ngSubmit)="submitForm()">
	<div class="flex-row ion-align-items-center ion-justify-content-center m-b-28 flex-wrap flex-direction-column">
		<ion-avatar class="avatar ion-margin-bottom" (click)="takePicture()">
			@if (imagePreviewUrl && imagePreviewUrl !== 'null') {
				<img [src]="imagePreviewUrl" alt="avatar" />
			}

			@if (!imagePreviewUrl) {
				<ion-icon name="add-outline" size="large" color="pink"></ion-icon>
			}
		</ion-avatar>

		<div class="w100 ion-text-center">
			<ion-text color="primary" (click)="takePicture()">{{ 'EDIT_PICTURE' | translate }}</ion-text>
		</div>
	</div>

	<ion-list mode="ios" [inset]="true" class="m-b-8">
		<ion-item color="light" mode="ios">
			<ion-input [label]="'USER_NAME' | translate" formControlName="user_name"></ion-input>
		</ion-item>
	</ion-list>

	<ion-note color="medium" class="sr-block ion-margin-horizontal font-size-12">
		{{ 'UNIQ_USERNAME_INFO' | translate }}
	</ion-note>

	<ion-list mode="ios" [inset]="true">
		<ion-item color="light">
			<ion-input [label]="'FIRST_NAME' | translate" formControlName="first_name"></ion-input>
		</ion-item>
	</ion-list>

	<ion-list mode="ios" [inset]="true">
		<ion-item color="light">
			<ion-input [label]="'LAST_NAME' | translate" formControlName="last_name"></ion-input>
		</ion-item>
	</ion-list>

	<ion-list mode="ios" [inset]="true" class="bg-light">
		<hb-code-phone [form]="form" fill="outline" mode="ios"></hb-code-phone>
	</ion-list>

	<div class="m-b-24">
		@if (authService?.principal?.role !== UserRolesEnum.ADMIN) {
			<ion-list mode="ios" [inset]="true">
				<ion-item color="light">
					<div>
						<div class="ion-margin-top m-b-10">{{ 'YOUR_ROLE_IN' | translate }}</div>
						<ion-radio-group formControlName="role" class="ion-margin-bottom sr-block flex" mode="md">
							<ion-radio color="pink" class="m-r-30" value="3" aria-label="Client" labelPlacement="end"
								>{{ 'CLIENT' | translate }}
							</ion-radio>

							<ion-radio color="pink" value="2" aria-label="Master" labelPlacement="end">{{ 'MASTER' | translate }} </ion-radio>
						</ion-radio-group>
					</div>
				</ion-item>
			</ion-list>
		}

		@if (+form?.value?.role === UserRolesEnum.MASTER || showServices) {
			<ion-list mode="ios" [inset]="true">
				<ion-item color="light">
					<hb-services-select [form]="form" [multiple]="true" mode="ios"></hb-services-select>
				</ion-item>
			</ion-list>
		}

		@if (showBirthdayComponent) {
			<ion-list mode="ios" [inset]="true">
				<ion-item color="light">
					<ion-input formControlName="birth_date" id="birthday-modal" [label]="'BIRTH_DATE' | translate" readonly="true"></ion-input>
				</ion-item>
			</ion-list>

			<ion-modal
				#modal
				class="auto-height"
				mode="ios"
				[initialBreakpoint]="1"
				[breakpoints]="[0, 1]"
				trigger="birthday-modal"
				(willDismiss)="onWillDismiss($event)"
			>
				<ng-template>
					<hb-birthday [birthday]="form?.value?.birth_date" [modal]="modal"></hb-birthday>
				</ng-template>
			</ion-modal>
		}
	</div>
</form>
