import { NgClass } from '@angular/common';
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { countryCodesMock, IMappedPhoneCountryCodes } from '@solar/core/src';
import { GeoLocationService } from '@solar/core/src/services/rest/geolocation';
import { Subscription } from 'rxjs';
import { ControlValueAccessorConnector } from '../../control-value-accessor.connector';
import { CodePhoneCountriesComponent } from './code-phone-countries/code-phone-countries.component';

@Component({
	templateUrl: './code-phone.component.html',
	selector: 'hb-code-phone',
	styleUrls: ['./code-phone.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CodePhoneComponent,
			multi: true,
		},
	],
	imports: [FormsModule, ReactiveFormsModule, NgClass, IonicModule, TranslateModule],
})
export class CodePhoneComponent extends ControlValueAccessorConnector implements OnInit, OnDestroy {
	@Input()
	public ngClass?:
		| string
		| string[]
		| Set<string>
		| {
				[klass: string]: any;
		  };

	@Input()
	public form?: UntypedFormGroup;

	@Input()
	public formName?: string;

	@Input()
	public mode?: 'ios' | 'md' = 'ios';

	@Input()
	public fill?: 'outline' | 'solid';

	public maxLength?: number = 8;

	public subs: Subscription;

	constructor(
		injector: Injector,
		private readonly modalCtrl: ModalController,
		private readonly geoLocationService: GeoLocationService,
	) {
		super(injector);
	}

	public ngOnInit(): void {
		const code = this.form.get('code');

		if (code?.value) {
			const country = this.getCountryByCode(code.value);
			this.setMask(country.mask, false);
		} else {
			this.subs = this.geoLocationService.getGeoLocationData().subscribe(res => {
				const country = countryCodesMock.find(c => c.iso === res.country);
				this.form.get('code').setValue(country?.code);
				this.setMask(country.mask, false);
			});
		}
	}

	protected getCountryByCode(code: string): { name: string; code: string; mask: string } {
		return countryCodesMock.find(c => c.code === code);
	}

	public async onPopover(): Promise<void> {
		const modal = await this.modalCtrl.create({
			component: CodePhoneCountriesComponent,
			breakpoints: [0, 1],
			initialBreakpoint: 1,
			canDismiss: true,
			mode: 'ios',
			componentProps: {
				form: this.form,
			},
		});

		const topModal = await this.modalCtrl.getTop();

		if (!topModal) {
			await modal.present();

			const { data } = await modal.onDidDismiss<IMappedPhoneCountryCodes>();

			if (data) {
				this.setMask(data.mask);
			}
		}
	}

	public setMask(mask: string, cleanPhone: boolean = true): void {
		const maxLength = Math.max(...mask.split(',').map(m => m.replace(/[\(\)-]/g, '').length));
		this.maxLength = maxLength > 0 ? maxLength : undefined;

		const phone = this.form?.get('phone');

		if (cleanPhone && phone?.value?.length) {
			this.form?.get('phone')?.setValue('');
			this.form?.get('phone')?.reset();
		}
	}

	public detectInputEvent(event): void {
		this.form?.get('phone')?.setValue((event.target as HTMLInputElement).value.replace(/[^0-9]+/g, ''));
	}

	public fixCaretPosition(event): void {
		const start = (event.target as HTMLInputElement).selectionStart;

		(event.target as HTMLInputElement).setSelectionRange(start, start);
	}

	public ngOnDestroy(): void {
		if (this.subs) {
			this.subs.unsubscribe();
		}
	}
}
