import { Injectable } from '@angular/core';
import { MainSlidersService } from '../../services';
import { FormEffects } from '../forms';
import { PagesEffects } from '../pages';
import { addMainSliderFormName, editMainSliderFormName, loadMainSlidesPageName } from './selectors';

@Injectable()
export class MainSliderEffects {
	public readonly $add = this.formEffects.getSendFormEffect(addMainSliderFormName, formState =>
		this.mainSlidersService.add(formState.formData),
	);

	public readonly $edit = this.formEffects.getSendFormEffect(editMainSliderFormName, formState => {
		return this.mainSlidersService.edit(formState.formData);
	});

	public readonly loadCategories$ = this.pagesEffects.getLoadPageEffect(loadMainSlidesPageName, () => this.mainSlidersService.loadAll());

	constructor(
		private readonly formEffects: FormEffects,
		private readonly mainSlidersService: MainSlidersService,
		private readonly pagesEffects: PagesEffects,
	) {}
}
