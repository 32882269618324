import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { Capacitor } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { AuthCredential, GoogleAuthProvider, OAuthProvider, UserCredential, signInWithCredential, signInWithPopup } from 'firebase/auth';
import { lastValueFrom } from 'rxjs';
import { UserDTO } from '../../auth/auth.dto';
import { AUTH_SERVICE_CONFIG, AuthServiceConfig } from '../../auth/auth.service';
import { IAppState } from '../../interfaces';
import { LoadPage, getUser } from '../../store';

@Injectable({
	providedIn: 'root',
})
export class FirebaseAuthService {
	constructor(
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		public router: Router,
		public store: Store<IAppState>,
		private readonly http: HttpClient,
		@Inject(AUTH_SERVICE_CONFIG) private readonly config: AuthServiceConfig,
		private readonly auth: Auth,
	) {}

	public async checkUserInServer(body): Promise<UserDTO> {
		try {
			return await lastValueFrom(this.http.post(`auth/google`, body));
		} catch (e) {
			console.log(e);
		}
	}
	// Sign in with email/password
	public SignIn(email: string, password: string): Promise<void> {
		return this.afAuth
			.signInWithEmailAndPassword(email, password)
			.then(result => {
				void this.SetUserData(result.user);

				this.afAuth.authState.subscribe(user => {
					if (user) {
						void this.router.navigate(['dashboard']);
					}
				});
			})
			.catch(error => {
				window.alert(error.message);
			});
	}
	// Sign up with email/password
	public SignUp(email: string, password: string): Promise<void> {
		return this.afAuth
			.createUserWithEmailAndPassword(email, password)
			.then(result => {
				/* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
				void this.SendVerificationMail();
				void this.SetUserData(result.user);
			})
			.catch(error => {
				window.alert(error.message);
			});
	}
	// Send email verfificaiton when new user sign up
	public SendVerificationMail(): Promise<void> {
		return (
			this.afAuth.currentUser
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				.then((u: any) => u.sendEmailVerification())
				.then(() => {
					void this.router.navigate(['verify-email-address']);
				})
		);
	}
	// Reset Forggot password
	public ForgotPassword(passwordResetEmail: string): Promise<void> {
		return this.afAuth
			.sendPasswordResetEmail(passwordResetEmail)
			.then(() => {
				window.alert('Password reset email sent, check your inbox.');
			})
			.catch(error => {
				window.alert(error);
			});
	}
	// Returns true when user is looged in and email is verified
	public get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem(this.config.storagePrincipalKey));
		return user !== null ? true : false;
	}
	/* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
	public SetUserData(user): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
		const userData: { uid: number; email: string; displayName: string; photoURL: string; emailVerified: boolean } = {
			uid: user.uid,
			email: user.email,
			displayName: user.displayName,
			photoURL: user.photoURL,
			emailVerified: user.emailVerified,
		};
		return userRef.set(userData, {
			merge: true,
		});
	}
	// Sign out
	public SignOut(): Promise<void> {
		return this.afAuth.signOut().then(() => {
			localStorage.removeItem(this.config.storagePrincipalKey);
			localStorage.removeItem(this.config.storageTokenKey);
			void this.router.navigate(['login']);
		});
	}

	public async getIdToken(): Promise<string | null> {
		try {
			const user = await this?.afAuth?.currentUser;

			if (user) {
				return await user.getIdToken(/* forceRefresh */ true);
			}

			return null;
		} catch (e) {
			throw e;
		}
	}

	public async saveUserToLocalStorage(user): Promise<void> {
		localStorage.setItem(this.config.storagePrincipalKey, JSON.stringify(user));
		JSON.parse(localStorage.getItem(this.config.storagePrincipalKey));

		const token = await this.getIdToken();

		localStorage.setItem(this.config.storageTokenKey, token);
	}

	public async loginWithApple(): Promise<void> {
		try {
			const user = await this.afAuth.currentUser;

			if (user) {
				await this.saveUserDataToDB(user?.displayName);
				return;
			}

			let signedUser;
			if (Capacitor.getPlatform() === 'web') {
				signedUser = await this.loginWithAppleWeb();
			} else {
				signedUser = await this.loginWithAppleNative();
			}

			if (signedUser) {
				await this.saveUserDataToDB(signedUser?.user?.displayName || user?.displayName);
			}
		} catch (e) {
			throw e;
		}
	}

	public async loginWithAppleWeb(): Promise<UserCredential> {
		try {
			const provider = this.setProvider('apple.com');
			return await signInWithPopup(this.auth, provider);
		} catch (e) {
			console.error(e);
		}
	}

	public async loginWithAppleNative(): Promise<UserCredential> {
		const options: SignInWithAppleOptions = {
			clientId: 'app.heybeauty.master.auth',
			redirectURI: 'https://heybeauty.firebaseapp.com/__/auth/handler',
			scopes: 'email name',
			state: '12345',
		};

		const result: SignInWithAppleResponse = await SignInWithApple.authorize(options);

		const displayName = result?.response?.givenName ? result?.response?.givenName + ' ' + result?.response?.familyName : null;

		const credential = this.setProvider('apple.com', result.response.identityToken);

		const allData = await signInWithCredential(this.auth, credential as AuthCredential);

		return { ...allData, user: { ...allData.user, displayName } };
	}

	public async loginWithGoogle(): Promise<void> {
		try {
			const user = await this.afAuth.currentUser;

			if (user) {
				await this.saveUserDataToDB();
				return;
			}

			let signedUser;
			if (Capacitor.getPlatform() === 'web') {
				signedUser = await this.loginWithGoogleWeb();
			} else {
				signedUser = await this.loginWithGoogleNative();
			}

			if (signedUser) {
				await this.saveUserDataToDB();
			}
		} catch (e) {
			throw e;
		}
	}

	public async loginWithGoogleWeb(): Promise<any> {
		try {
			const provider = new GoogleAuthProvider();

			return await this.afAuth.signInWithPopup(provider);
		} catch (e) {
			console.log(e);
		}
	}

	public async loginWithGoogleNative(): Promise<any> {
		const options: SignInWithAppleOptions = {
			clientId: 'app.heybeauty.master.auth',
			redirectURI: 'https://heybeauty.firebaseapp.com/__/auth/handler',
			scopes: 'email name',
			state: '12345',
		};

		const result: SignInWithAppleResponse = await SignInWithApple.authorize(options);

		const credential = this.setProvider('google.com', result.response.identityToken);

		return await this.afAuth.signInWithCredential(credential as AuthCredential);
	}

	public setProvider(providerName: 'google.com' | 'apple.com', idToken?: string): OAuthProvider | AuthCredential {
		const provider = new OAuthProvider(providerName);
		provider.addScope('email');
		provider.addScope('name');

		if (idToken) {
			return provider.credential({ idToken });
		}

		return provider;
	}

	private async saveUserDataToDB(displayName?: string): Promise<void> {
		const user = await this.afAuth.currentUser;

		if (user) {
			const idToken = await user.getIdToken(/* forceRefresh */ true);

			const userData = await this.checkUserInServer({ idToken, displayName });

			await this.saveUserToLocalStorage(userData);

			this.store.dispatch(LoadPage({ path: getUser, reqData: { id: userData.id } }));
		}
	}
}
