@if (text) {
	<div>
		@if (isExpanded) {
			<hb-see-translate [text]="text"></hb-see-translate>
		} @else {
			<hb-see-translate [text]="truncatedText">
				@if (maxLength < text.length) {
					<span afterText>
						...
						<ion-text class="font-weight-500 white-space-no-wrap font-size-14" color="primary" (click)="toggleText()">
							{{ 'MORE' | translate }}
						</ion-text>
					</span>
				}
			</hb-see-translate>
		}
	</div>
}
