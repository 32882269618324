@if (user?.all_categories && !isEmptyData(user?.all_categories)) {
	<div class="font-size-10 m-t-4 m-b-4">
		<ion-text [color]="color" class="white-space-normal" [ngClass]="ngClass">
			@for (c of user?.all_categories; track i; let i = $index; let last = $last) {
				<span>
					{{ c | serverTranslate | titlecase }}
				</span>
				@if (!last) {
					,
				}
			}
		</ion-text>
	</div>
}
