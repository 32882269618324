import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

@Directive({
	selector: '[platformCheck]',
})
export class PlatformCheckDirective {
	constructor(
		private readonly platform: Platform,
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainerRef: ViewContainerRef,
	) {}

	@Input() public set platformCheck(targetPlatforms: Platforms[]) {
		const isTargetPlatform = targetPlatforms.some(platform => this.platform.is(platform));

		if (isTargetPlatform) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainerRef.clear();
		}
	}
}
