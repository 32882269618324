import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input, signal } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { PrintersService } from '@solar/core/src/services/rest/printers/printers.service';
import { ToastService } from '@solar/core/src/services/toast/toast.service';
import Socket from '@vendus/sockets-for-cordova';

export interface IReceiptData {
	title: string;
	qty: number;
	price: number;
	currency: string;
}

@Component({
	selector: 'hb-print-receipt-btn',
	templateUrl: './print-receipt-btn.component.html',
	styleUrls: ['./print-receipt-btn.component.scss'],
	providers: [IonicModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PrintReceiptBtnComponent {
	public item = input<IReceiptData>();

	protected isPending = signal<boolean>(false);

	constructor(
		private readonly printersService: PrintersService,
		private readonly toastService: ToastService,
		protected readonly platform: Platform,
	) {}

	public async onPrint(): Promise<void> {
		this.isPending.set(true);

		const it: IReceiptData = {
			title: this.item().title,
			qty: 1,
			price: +this.item().price,
			currency: this.item().currency,
		};

		try {
			const data = await this.printersService.printTheBill([it]);

			data.results.forEach((result: any) => {
				const receiptData = new Uint8Array(result?.receiptData?.data);
				// Открываем соединение с принтером

				if (receiptData) {
					const socket = new Socket();

					socket.open(
						result?.printer,
						9100,
						() => {
							void this.toastService.presentToast(`Connected to printer ${result?.printer}`);

							socket.write(
								receiptData,
								() => {
									void this.toastService.presentToast('Receipt printed successfully');
								},
								() => {
									void this.toastService.presentToast('Error sending data');
								},
							);
						},
						error => {
							void this.toastService.presentToast(error?.message);
						},
					);
				}
			});
		} catch (error) {
			void this.toastService.presentToast(error.message);
		}

		this.isPending.set(false);
	}
}
