import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IUserPermissions } from './user-permissions.dto';

@Injectable({
	providedIn: 'root',
})
export class UserPermissionsService {
	constructor(private readonly http: HttpClient) {}

	public async setPermissions(body: IUserPermissions): Promise<IUserPermissions> {
		return await lastValueFrom(this.http.post<IUserPermissions>(`/user-permissions`, body));
	}
}
